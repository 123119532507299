import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthInterface } from './auth.interface';
import { BehaviorSubject, Observable, throwError, from, Subject } from 'rxjs'
import { loggedInUser } from '../interfaces/logged-in-user';
import { environment } from 'src/environments/environment';
import { finalize, catchError, tap, shareReplay, map, switchMap } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { Role } from '../interfaces/role.interface';

const TOKEN_KEY = 'auth-token';
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const ROLE = 'ROLE';
const expires_in = 'expires_in';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  //url = "/api";
  //url = "http://localhost:3000/users";
  loginResponse;
  url: string = environment.baseUrl + "oauth/token";
  urlBase: string = environment.baseUrl;
  //url = "username=admin@corvus.com&password=Administrator&grant_type=password"
  //const params = {};
  
  private authSubject = new BehaviorSubject<loggedInUser>(null); //Create state for storing login state
  user$ : Observable<loggedInUser> = this.authSubject.asObservable();
  isLoggedIn$ : Observable<boolean>;
  isLoggedOut$ : Observable<boolean>;
  roleSubject = new BehaviorSubject<any>(null);
  smsSessionId = new BehaviorSubject<string>(null);

  private isProviderLoggedIn = new Subject<any>();

  // headerss = {
  //   'Content-Type': 'application/json',
  //   'Authorization': 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
  //   'Access-Control-Allow-Origin': '*',
  //      'Accept', 'application/json'
  // };

  //Add BehaviourSubject, and assign to a variable
  authenticationState = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    ) { }

  //  //START REFRESHG TOKEN TEST
  //     async getAccessTokenUsingRefreshToken() {
      
  //     console.log("Started Getting Resfresh Token...");
  //     //Get refresh token from storage
  //     const refreshToken = this.storage.get(REFRESH_TOKEN).then((result) => {
        // resolve(result);
  //       return result;
  //     });
      
  //     console.log(refreshToken);
  //     console.log(await refreshToken);

  //     //Genrate params for token refreshing
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         // 'Authorization': `Bearer ${await refreshToken}`,
  //         'Authorization': 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
  //         'Accept': 'application/json, text/plain, */*'
  //       })
  //     };

  //     const params = new HttpParams()
  //     .set("grant_type", "refresh_token")
  //     .set("refresh_token", await refreshToken);
      
  //     return this.http.post<any>(`${this.url}`, {params}, httpOptions )
  //     .pipe(
  //       tap(tokens => {
  //       console.log(tokens[ACCESS_TOKEN]);
  //       console.log(tokens[REFRESH_TOKEN]);
  //       console.log(tokens);
  //       this.storage.set(ACCESS_TOKEN, tokens[ACCESS_TOKEN]);
  //       this.storage.set('expires_in', tokens[expires_in]);
        
  //       // let expires_in = this.storage.get(TOKEN_KEY.expires_in);
  //       // var now = new Date();
  //       // let expiryDate = new Date(now.getTime() + expires_in*1000);
  //       // this.storage.set('expires_in', expiryDate);
        
  //       console.log(this.storage.get(ACCESS_TOKEN));
  //     })
  //     );
  
  //     }


  //START REFRESHG TOKEN TEST
  getAccessTokenUsingRefreshToken() {
      
      console.log("Started Getting Resfresh Token...");
      //Get refresh token from storage
      let TOKEN = localStorage.getItem('TOKEN');
      const refreshToken = TOKEN['refresh_token'];

      console.log(refreshToken);

      //Genrate params for token refreshing
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${await refreshToken}`,
          'Authorization': 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
          'Accept': 'application/json, text/plain, */*'
        })
      };

      const params = new HttpParams()
      .set("grant_type", "refresh_token")
      .set("refresh_token", refreshToken);
      
      return this.http.post<any>(`${this.url}`, {params}, httpOptions )
        .pipe(
          tap(tokens => {
            console.log(tokens[ACCESS_TOKEN]);
            console.log(tokens[REFRESH_TOKEN]);
            console.log('tokens', tokens);
            this.storage.set(ACCESS_TOKEN, tokens[ACCESS_TOKEN]);
            this.storage.set('expires_in', tokens[expires_in]);
            
            localStorage.setItem('currentUser', JSON.stringify({ token: tokens }));
            localStorage.setItem('TOKEN', JSON.stringify({ ACCESS_TOKEN: tokens[ACCESS_TOKEN] }));
           
            // let expires_in = this.storage.get(TOKEN_KEY.expires_in);
            // var now = new Date();
            // let expiryDate = new Date(now.getTime() + expires_in*1000);
            // this.storage.set('expires_in', expiryDate);
            
            console.log(this.storage.get(ACCESS_TOKEN));
          })
      );

  }
    
  
  
  
  
  public _loginUser(userInfo){
   
    var headers = new HttpHeaders();  //SET HEASDERS
    headers.append("Accept", 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0');
    
    console.log("userInfo: " + JSON.stringify(userInfo));

    return this.http.post(
      "/api" +
      "?username=" + userInfo.username +
      "&password=" + userInfo.password +
      "&grant_type=" + "password", 
      userInfo, { headers:headers, observe: 'response' });
    // localStorage.setItem('ACCESS_TOKEN', "access_token");
  }

  /**Route Guards uses this to check autentication */
  isAuthenticated() {
    // let isAuthenticated = JSON.parse(localStorage.getItem('currentUser'));
    if(JSON.parse(localStorage.getItem('currentUser')))
      {
        // console.log('isAuthenticated: ', isAuthenticated);
        this.authenticationState.next(true);
        // console.log("true");
        return true;
      }else{
          // console.log('is Not Authenticated');
          this.authenticationState.next(false);
          // console.log("False");
          return false;
      }
    // return this.authenticationState.value;
  }

  /** !!!IMPORTANT - SET ROLE ON LOGIN & REDIRECT USER */ 
  setUserRole(loginResponseL, returnUrl) {
    var auth_options = {
      headers: {
        Authorization: `Bearer ${loginResponseL.access_token}`
      }
    }
    return this.http.get<Role>(environment.baseUrl + "api/User/GetUserRole", auth_options)
    .pipe(
      // finalize(() => this.loadingService.loadingOff())    
    )
    .subscribe(role => {
      console.log("Role IS: " + JSON.stringify(role.name));
      
      if(role){
        console.log("Role IS Existing as: " + JSON.stringify(role.name));
        if(role.name === "provider"){
          returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
          this._addLoggedInDoctorToStorage(returnUrl);
                // if(returnUrl === null){
                //   console.log('No ReturnUrl: Provider');
                //   this.router.navigateByUrl("/providers");
                // }else{
                // // this.router.navigateByUrl("/providers");
                // this.router.navigate([returnUrl]);
                // // this.router.navigateByUrl("/consultation-services");
                // }
          
        }else if(role.name === "client"){
          this._addLoggedInUserToStorage(returnUrl);
          console.log(returnUrl);
          
              // if(returnUrl === null){
              //   console.log('No ReturnUrl: client');
              //   this.router.navigateByUrl("/dashboard");
              // }else{
              // this.router.navigate([returnUrl]);
              // // this.router.navigateByUrl("/consultation-services");
              // }
        }else if(role.name === "admin"){
          returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
          this._addLoggedInAdminToStorage(returnUrl);
          console.log(returnUrl);
        }else if(role.name === 'clinic_admin'){
          returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
          this._addLoggedInClinicToStorage(returnUrl);
          console.log(returnUrl);
        } else if(role.name === 'clinic_doctor'){
          returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
          this._addLoggedInClinicDoctorToStorage(returnUrl);
          console.log(returnUrl);
        } else{this.router.navigateByUrl("/consultation-services");}


        this.storage.set('ROLE', role.name).then(() => {
          
        });
      }

      

    },(error: HttpErrorResponse) => {
        return console.log(error);
      });
  }

   /**THIS GETS USER / CLIENT AND ADDS DETAILS TO LOCAL STORAGE - Hardcodded Paul -  6734*/
   _addLoggedInUserToStorage(returnUrl?: any){
    if(this.authenticationState){
      return this.http.get<loggedInUser>(this.urlBase + "api/User/GetUserProfile")
      .pipe(
        finalize(() => this.loadingService.loadingOff()),
        shareReplay(),
      )
      .subscribe((res: any) => {
        // console.log(res);
       // Add LoggedInUser Object to subjectStore - this can be shared throught the application
        let userObj = {id: res.id, firstName: res.firstName, lastName: res.lastName, profilePicDocumentKey: res.profilePicDocumentKey, phone: res.phoneNumber, email: res.email, timezone: res.timezone, blinkSessionClientId: res.blinkSessionId, ROLE: 'client', bahmniUuid : res.bahmniUuid, bahmniClinicAdmin: res.bahmniClinicAdmin, bahmniProviderAvailableForAppointments: res.bahmniProviderAvailableForAppointments, location : res.location, country : res.country, countryCallingCode : res.countryCallingCode, state : res.state, city : res.city, zipcode : res.zipcode };
        //console.log("userObj: " + JSON.stringify(userObj));

        //Pls check
        // res.tap(() => this.authSubject.next(userObj));
        localStorage.setItem('currentUser', JSON.stringify(userObj));
        this.roleSubject.next('client');
        this.authSubject.next(userObj);
   
        //console.log("aSub: " + JSON.stringify(this.authSubject.getValue()));
        
        // localStorage.setItem('currentUser', JSON.stringify(userObj));
        
        /**THIS DIRECTS LOGGED IN USER */
        if(returnUrl === null || !returnUrl){
          if(this.route.snapshot.queryParamMap.get('isBookAppointment')) {
            window.location.href = "/book-appointment";
          } else {
            this.router.navigateByUrl("/dashboard");
          }
        }else{
          this.router.navigate([returnUrl]);
        // this.router.navigateByUrl("/consultation-services");
        }

        return JSON.parse(localStorage.getItem('currentUser'));

        

      },(error: HttpErrorResponse) => (console.log("i am an errir" + error)))
    }else{
        console.log("No values");
        localStorage.removeItem('currentUser'); //if user is not logged in remove his information
      }



    // if(this.authenticationState){
    //   return this.http.get<loggedInUser>(this.urlBase + "api/User/GetUserProfile")
    //   .pipe(
    //     finalize(() => this.loadingService.loadingOff()),
    //     shareReplay(),
    //   )
    //   .subscribe((res: any) => {
    //     // console.log(res);
    //    // Add LoggedInUser Object to subjectStore - this can be shared throught the application
    //     let userObj = {id: res.id, firstName: res.firstName, lastName: res.lastName, profilePicDocumentKey: res.profilePicDocumentKey, phone: res.phoneNumber, email: res.email, timezone: res.timezone, blinkSessionClientId: res.blinkSessionId, ROLE: 'client'};
    //     //console.log("userObj: " + JSON.stringify(userObj));

    //     //Pls check
    //     // res.tap(() => this.authSubject.next(userObj));
    //     localStorage.setItem('currentUser', JSON.stringify(userObj));
    //     this.roleSubject.next('client');
    //     this.authSubject.next(userObj);
   
    //     //console.log("aSub: " + JSON.stringify(this.authSubject.getValue()));
        
    //     localStorage.setItem('currentUser', JSON.stringify(userObj));
    //     return JSON.parse(localStorage.getItem('currentUser'));
    //   },(error: HttpErrorResponse) => (console.log("i am an errir" + error)))
    // }else{
    //   console.log("No values");
    //   localStorage.removeItem('currentUser'); //if user is not logged in remove his information
    // }
  }

  /**THIS GETS DOCTOR / PROVIDER AND ADDS DETAILS TO LOCAL STORAGE - Hardcodded Shr - 7988 */
  _addLoggedInDoctorToStorage(returnUrl: any){
    if(this.authenticationState){
      return this.http.get<loggedInUser>(this.urlBase + "api/User/GetDoctorProfile")
      .pipe(
        map((res: loggedInUser) => {
          // Add LoggedInUser Object to subjectStore - this can be shared throught the application
        let userObj = {id: res.id, firstName: res.firstName, lastName: res.lastName, profilePicDocumentKey: res.profilePicDocumentKey, phone: res.phoneNumber, email: res.email, bahmniUuid: res.bahmniUuid, bahmniClinicAdmin: res.bahmniClinicAdmin, bahmniProviderAvailableForAppointments: res.bahmniProviderAvailableForAppointments, timezone: res.timezone, blinkSessionProviderId: res.blinkSessionId, ROLE: 'provider'};
        //USING mobile storage
        // this.storage.set('currentUser', userObj).then(data => this.authSubject.next(data));
        // this.storage.get('currentUser').then(data => this.authSubject.next(data));

        localStorage.setItem('currentUser', JSON.stringify(userObj));
        this.roleSubject.next('provider');
        this.authSubject.next(userObj);
        //Pls check - adding logged in user to local storage
        this.storage.set('currentUser', userObj).then((usrObj) => this.authSubject.next(usrObj))
        //this.authSubject.next(userObj);
        //console.log("authSub User: " + JSON.stringify(this.authSubject.getValue()) + " " + new Date());

        }),
        finalize(() => this.loadingService.loadingOff()),
        shareReplay(),
        //finalize(() => this.loadingService.loadingOff())
      )//ed pipe
      
      .subscribe((res: any) => {
     
        // Add LoggedInUser Object to subjectStore - this can be shared throught the application
        //let userObj = {id: res.id, firstName: res.firstName, lastName: res.lastName, profilePic: res.profilePic, timezone: res.timezone, blinkSessionProviderId: res.blinkSessionId ? res.blinkSessionId : 7988};
        //console.log("userObj: " + JSON.stringify(userObj));

        // //USING mobile storage
        // this.storage.set('currentUser', userObj).then(data => this.authSubject.next(data));
        // this.storage.get('currentUser').then(data => this.authSubject.next(data));


        

        // let dtls: any = JSON.parse(localStorage.getItem('currentUser'));
        // console.log(dtls + " " + new Date());

        /**THIS REDIRECTS LOGGED IN DOCTOR */
        if(returnUrl === null){
          console.log('No ReturnUrl: Provider');
          this.router.navigateByUrl("/providers");
        }else{
        // this.router.navigateByUrl("/providers");
        this.router.navigate([returnUrl]);
        // this.router.navigateByUrl("/consultation-services");
        }
        

        return JSON.parse(localStorage.getItem('currentUser'));
        // return this.authSubject.getValue();
      });
    }else{
      localStorage.removeItem('currentUser'); //if user is not logged in remove his information
    }
  }

  /**THIS GETS DOCTOR / PROVIDER AND ADDS DETAILS TO LOCAL STORAGE - Hardcodded Shr - 7988 */
  _addLoggedInAdminToStorage(returnUrl: any){
    if(this.authenticationState){
      return this.http.get<loggedInUser>(this.urlBase + "api/User/GetDoctorProfile")
      .pipe(
        map((res: loggedInUser) => {
          // Add LoggedInUser Object to subjectStore - this can be shared throught the application
        let userObj = {id: res.id, firstName: res.firstName, lastName: res.lastName, profilePicDocumentKey: res.profilePicDocumentKey, phone: res.phoneNumber, email: res.email, bahmniUuid: res.bahmniUuid, bahmniClinicAdmin: res.bahmniClinicAdmin, bahmniProviderAvailableForAppointments: res.bahmniProviderAvailableForAppointments, timezone: res.timezone, blinkSessionProviderId: res.blinkSessionId, ROLE: 'admin'};
        //USING mobile storage
        // this.storage.set('currentUser', userObj).then(data => this.authSubject.next(data));
        // this.storage.get('currentUser').then(data => this.authSubject.next(data));

        localStorage.setItem('currentUser', JSON.stringify(userObj));
        this.roleSubject.next('admin');
        this.authSubject.next(userObj);
        //Pls check - adding logged in user to local storage
        this.storage.set('currentUser', userObj).then((usrObj) => this.authSubject.next(usrObj))
        //this.authSubject.next(userObj);
        //console.log("authSub User: " + JSON.stringify(this.authSubject.getValue()) + " " + new Date());

        }),
        finalize(() => this.loadingService.loadingOff()),
        shareReplay(),
        //finalize(() => this.loadingService.loadingOff())
      )//ed pipe
      
      .subscribe((res: any) => {
     
        /**THIS REDIRECTS LOGGED IN DOCTOR */
        if(returnUrl === null){
          console.log('No ReturnUrl: Admin');
          this.router.navigateByUrl("/admin");
        }else{
        // this.router.navigateByUrl("/providers");
        this.router.navigate([returnUrl]);
        // this.router.navigateByUrl("/consultation-services");
        }
        

        return JSON.parse(localStorage.getItem('currentUser'));
        // return this.authSubject.getValue();
      });
    }else{
      localStorage.removeItem('currentUser'); //if user is not logged in remove his information
    }
  }


  _addLoggedInClinicToStorage(returnUrl: any){
    if(this.authenticationState){
      return this.http.get<loggedInUser>(this.urlBase + "api/User/GetDoctorProfile")
      .pipe(
        map((res: loggedInUser) => {
          let userObj = {id: res.id, firstName: res.firstName, lastName: res.lastName, profilePicDocumentKey: res.profilePicDocumentKey, phone: res.phoneNumber, email: res.email, bahmniUuid: res.bahmniUuid, bahmniClinicAdmin: res.bahmniClinicAdmin, bahmniProviderAvailableForAppointments: res.bahmniProviderAvailableForAppointments, timezone: res.timezone, blinkSessionProviderId: res.blinkSessionId, ROLE: 'clinic'};
          localStorage.setItem('currentUser', JSON.stringify(userObj));
          this.roleSubject.next('clinic');
          this.authSubject.next(userObj);
          this.storage.set('currentUser', userObj).then((usrObj) => this.authSubject.next(usrObj))
        }), finalize(() => this.loadingService.loadingOff()), shareReplay(),
      )//ed pipe
      .subscribe((res: any) => {
        /**THIS REDIRECTS LOGGED IN DOCTOR */
        if(returnUrl === null){
          this.getLoggedInProvider(null);
        }else{
          this.router.navigate([returnUrl]);
        }
        return JSON.parse(localStorage.getItem('currentUser'));
      });
    }else{
      localStorage.removeItem('currentUser');
    }
  }

  _addLoggedInClinicDoctorToStorage(returnUrl: any) {
    if(this.authenticationState){
      return this.http.get<loggedInUser>(this.urlBase + "api/User/GetDoctorProfile")
      .pipe(
        map((res: loggedInUser) => {
          let userObj = {id: res.id, firstName: res.firstName, lastName: res.lastName, profilePicDocumentKey: res.profilePicDocumentKey, phone: res.phoneNumber, email: res.email, bahmniUuid: res.bahmniUuid, bahmniClinicAdmin: res.bahmniClinicAdmin, bahmniProviderAvailableForAppointments: res.bahmniProviderAvailableForAppointments, timezone: res.timezone, blinkSessionProviderId: res.blinkSessionId, ROLE: 'clinic_doctor'};
          localStorage.setItem('currentUser', JSON.stringify(userObj));
          this.roleSubject.next('clinic_doctor');
          this.authSubject.next(userObj);
          this.storage.set('currentUser', userObj).then((usrObj) => this.authSubject.next(usrObj))
        }), finalize(() => this.loadingService.loadingOff()), shareReplay(),
      )//ed pipe
      .subscribe((res: any) => {
        /**THIS REDIRECTS LOGGED IN CLINIC DOCTOR */
        if(returnUrl === null){
          this.getLoggedInProvider(null);
        }else{
          this.router.navigate([returnUrl]);
        }
        return JSON.parse(localStorage.getItem('currentUser'));
      });
    }else{
      localStorage.removeItem('currentUser');
    }
  }

  getLoggedInProvider(sessionId) {
    if(localStorage.getItem('TOKEN') && localStorage.getItem('currentUser')) {

      const token = JSON.parse(localStorage.getItem('TOKEN'));
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const auth_options = {
        headers: {
          Authorization: `Bearer ${token.access_token}`
        }
      }

      return this.http.get(this.urlBase + `bahmni/clinic/getByProvider?jSessionId=`+ sessionId, auth_options)
      .subscribe((res:any) => {
        this.isProviderLocation(res, currentUser, token);
        this.router.navigate(['/login']);
        localStorage.removeItem('currentUser'); 
      });
    } 
  }

  isProviderLocation(location: any, user, token) {
    this.isProviderLoggedIn.next({location, user, token});
  }

  getStatusProvider(): Observable<any> {
    return this.isProviderLoggedIn.asObservable();
  }

  clearProviderStatus() {
    this.isProviderLoggedIn.next();
  }

  public _isLoggedIn(){
    return localStorage.getItem(ACCESS_TOKEN) !== null;

  }

  public _logoutUser(){
    localStorage.removeItem(ACCESS_TOKEN);
  }


  /** !!!IMPORTANT - Logout User by deleting all access tokens*/
  async logout() {
    await this.storage.remove(ACCESS_TOKEN);
    await this.storage.remove(REFRESH_TOKEN);
    await this.storage.remove(TOKEN_KEY);
    await this.storage.remove(ROLE);
    this.storage.clear();
    this.authenticationState.next(false);
    this.authSubject.next(null);//This sets value of user$ to logged out
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('currentUser'); 
    localStorage.removeItem('SESSION'); 
    localStorage.clear;

    const date = new Date();
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
    var cookie1 = 'bahmni.user'+"=; expires="+date.toUTCString()+"; path=/";
    window.document.cookie = cookie1;
    var cookie2 = 'bahmni.user.location'+"=; expires="+date.toUTCString()+"; path=/";
    window.document.cookie = cookie2;
    
    this.router.navigateByUrl('login');
  }

  _getLoggedInUserFromStorage(userInfo: any){
    // if(this.authSubject){
    //let loggedInUserDetails = localStorage.getItem('currentUser');
    //localStorage.getItem('currentUser');
    JSON.parse(localStorage.getItem('currentUser'));
    this.storage.get('currentUser').then((user) => this.authSubject.next(user));
    return JSON.parse(userInfo);
  }

  /**VERIFY ACCOUNT USING TOKEN */
  _confirmAccountTokenValid(tokenId: any){
    const params = new HttpParams()
    .set("token", tokenId);
    
    return this.http.get(this.urlBase + "api/User/confirm-account", {params});
  }



  /** LOGIN USER STARTS HERE */
  _login(userInfo: AuthInterface) {
    this.loadingService.loadingOn('...Authenticating');
    return this.http.post(
      this.url +
      "?username=" + userInfo.username +
      "&password=" + userInfo.password +
      "&grant_type=" + "password", 
      userInfo)
      .pipe(
        // tap(_ => this.loadingService.loadingOn('...Authenticating')),
        //shareReplay(),
        
        finalize(() => this.loadingService.loadingOff()),
        catchError(this.handleLoginError),
      )
      // .subscribe(res => 
      // {
      //   //Response with tokens
      //   this.loginResponse = res;
      //   //console.log(JSON.stringify(res));
      //   //console.log("Login Response: " + JSON.stringify(this.loginResponse.REFRESH_TOKEN));
      //   return this.storage.set("USER_INFO", userInfo.username).then(() => {
      //     this.storage.set(ACCESS_TOKEN, this.loginResponse.access_token);
      //     this.storage.set('REFRESH_TOKEN', this.loginResponse.refresh_token);
      //     this.storage.set('TOKEN_KEY', this.loginResponse);
          
          
      //     //Check if token exists in storage, and then update authState to true
      //     this.checkToken();

      //     this.authenticationState.next(true);
            
      //   //Redirect Client / Provider to Dashboard / authorized pages
      //   this.setUserRole();

          
      //   })
      // },//Come back here - What if someone credentials is not valid for login?
      // (error: HttpErrorResponse) => 
      // {
      //   console.log("Error Auhenticating User1: " + error)
      //   console.log("Error Auhenticating User: " + error);
      //   this.authenticationState.next(false);
      //   //this.apiervice.presentAlertApi("LOGIN STATUS", "Please check your Username or Password" + error, 'Try Again');
      //   alert("Please check your Username or Password");
      //   //this.logout();
      // })
    
  }

  /**OTP LOGIN */
  otpLogin(userInfo){
    return this.http.post(`${this.urlBase}api/User/sendOtp`, userInfo)
      // .pipe(
      //   tap(data => console.log(data)),
      //   finalize(a => {
      //     this.loadingService.loadingOff(); 
      //   })
      // )
  }

    /**VERIFY ACCOUNT USING TOKEN */
    _verifyOtp(sessionId: any, otpEntered: any){
      const params = new HttpParams()
      .set("sessionId", sessionId)
      .set("otpEntered", otpEntered);

      let userDetail = {
        sessionId: sessionId,
        otpEntered: otpEntered
      }
      
      return this.http.post(`${this.urlBase}api/User/verifyOtp`, userDetail, {params});
    }

    /**VERIFY REGISTRATION OTP ON PHONE SIGNUP */
    _verifyPhoneRegistrationOtp(verifyUserobject: any){
      const params = new HttpParams()
      .set("sessionId", verifyUserobject.sessionId)
      .set("otpEntered", verifyUserobject.otpEntered);

      let userDetail = {
        sessionId: verifyUserobject.sessionId,
        otpEntered: verifyUserobject.otpEntered
      }
      
      return this.http.post(`${this.urlBase}api/User/verifyOtp`, userDetail, {params});
    }

  /** Handle Login Errors */
  handleLoginError(error: HttpErrorResponse){
    console.log('error .error!' + JSON.stringify(error.error));
    console.log('error headers!' + JSON.stringify(error.headers));
    console.log('error message' + JSON.stringify(error.message));
    console.log('error name!' + JSON.stringify(error.name));
    console.log('error status!' + JSON.stringify(error.status));
    console.log('error statusText!' + JSON.stringify(error.statusText));
    console.log('error type!' + JSON.stringify(error.type));
      if (error.status === 401) {//Unauthorized
        console.log("Error Status 401: " + JSON.stringify(error.error.error));
        let message = error.error.error;
        
        
        //this.authenticationState.next(false);
        this.logout();
      }else if (error.status === 400) {//Unauthorized
        console.log("Error Status 400: " + JSON.stringify(error.error.error));
        let message = error.error.error;
        
        //this.presentAlertApi("LOGIN STATUS", "Please check your Username or Password" + message, 'Try Again')
        
        //this.authenticationState.next(false);
        //this.logout();
      }else  if (error.status === 0) {
        console.log("Error Status is 0: " + JSON.stringify(error));
      }
    return throwError(error);
  }

   /**FORGOT PASSWORD FORM */
   _forgotPasswordForm(username: any){
    return this.http.post(this.urlBase + "api/User/forgot-password", username);
  }

  /**CONFIRM RESET USING TOKEN */
  _confirmResetTokenValid(tokenId: any, isChangePassword: any){
    let params: HttpParams;
    if(tokenId) {
      params = new HttpParams().set("token", tokenId);
    }  
    if(isChangePassword) {
      params = new HttpParams().set("isChangePassword", isChangePassword);
    }
    
    return this.http.get(this.urlBase + "api/User/confirm-reset", {params});
  }

  /** RESETS PASSWORD */
  _resetPassword(userInfo: any, isChangePassword: any){
    let params: HttpParams;
    if(userInfo.token) {
      params = new HttpParams().set("token", userInfo.token);
    }  
    if(isChangePassword) {
      params = new HttpParams().set("isChangePassword", isChangePassword);
    }
    
    let userEmail = {
      password: userInfo.password,
      username: userInfo.username,
      phoneNumber: userInfo.phoneNumber
    }

    // return this.http.post(this.urlBase + "api/User/reset-password", userInfo, {params});
    return this.http.post(
      this.urlBase + 
      "api/User/reset-password",
      //"?token=" + userInfo.token,
      // "&password=" + userInfo.password, 
      userEmail,
      {params})
  }

  /**THIS METHOD GETS PROVIDER / DOCTOR FROM LOCAL STORAGE*/
  _getProviderFromStorage(){
    let loggedInUserDetails = localStorage.getItem('currentUser');
    this._getLoggedInUserFromStorage(loggedInUserDetails);
    return JSON.parse(loggedInUserDetails);
  }

   //THis simply gets the logged in user from local storage into json object
   _getLoggedInUserFromStorageSimple(){
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  


//--------------------------------------------------------REFRESH TOKEN-------------------------
// refreshToken() {
//   //return tokenObj
//   //then store new tokenObject into storage
//       console.log("Started Getting Resfresh Token...");
//       //Get refresh token from storage
//       const refreshToken = this.storage.get(REFRESH_TOKEN).then((result) => {
//         resolve(result);
//         // return result;
//       });
      
//       console.log(refreshToken);
//       console.log(refreshToken);

//       //Genrate params for token refreshing
//       const httpOptions = {
//         headers: new HttpHeaders({
//           'Content-Type': 'application/json',
//           // 'Authorization': `Bearer ${await refreshToken}`,
//           'Authorization': 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
//           'Accept': 'application/json, text/plain, */*'
//         })
//       };

//       const params = new HttpParams()
//       .set("grant_type", "refresh_token")
//       .set("refresh_token", await refreshToken);
      
//       return this.http.post<any>(`${this.url}`, {params}, httpOptions )
//       .pipe(
//         tap(tokens => {
//         console.log(tokens[ACCESS_TOKEN]);
//         console.log(tokens[REFRESH_TOKEN]);
//         console.log(tokens);
//         this.storage.set(ACCESS_TOKEN, tokens[ACCESS_TOKEN]);
//         this.storage.set('expires_in', tokens[expires_in]);
        
//         // let expires_in = this.storage.get(TOKEN_KEY.expires_in);
//         // var now = new Date();
//         // let expiryDate = new Date(now.getTime() + expires_in*1000);
//         // this.storage.set('expires_in', expiryDate);
        
//         console.log(this.storage.get(ACCESS_TOKEN));
//       })
//       );


//   // return this.http.post<any>(`${config.apiUrl}/refresh`, {
//   //   'refreshToken': this.getRefreshToken()
//   // }).pipe(tap((tokens: Tokens) => {
//   //   this.storeJwtToken(tokens.jwt);
//   // }));
// }

getAccessTokenObjectUsingRefreshToken(): Observable<string> {
  return from(this.storage.get(REFRESH_TOKEN)).pipe(
    tap(n => console.log(n)),
    switchMap(refreshToken => {
      console.log("refreshToken: ", refreshToken);
      //Genrate params for token refreshing
      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //     // 'Authorization': `Bearer ${refreshToken}`,
      //     'Authorization': 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
      //     'Accept': 'application/json, text/plain, */*'
      //   })
      // };

      // const params = new HttpParams()
      // .set("grant_type", "refresh_token")
      // .set("refresh_token", await refreshToken);
      
      // return this.http.post<any>(`${this.url}`, {params}, httpOptions )
      

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${refreshToken}`,
          'Authorization': 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
          'Accept': 'application/json, text/plain, */*'
        })
      };
      const params = new HttpParams()
      .set("grant_type", "refresh_token")
      .set("refresh_token", refreshToken);
      return this.http.post<any>(`${this.urlBase}oauth/token`, {params}, httpOptions);
    }),
    tap(d => console.log(d)),
    map(response => response.access_token),
    tap(y => console.log(y)),
    tap(accessToken => this.storage.set(ACCESS_TOKEN, accessToken))
  );
}


}
