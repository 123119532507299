import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

// import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
// import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import {
  MatTableModule,
  MatStepperModule,
  // MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule,
  //Used
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatBadgeModule,
  MatCardModule,
  MatDatepickerModule, 
  MatNativeDateModule,
  MatMenuModule,
  MatChipsModule,  
  MatDialogModule,
  MatTabsModule,
  MatSnackBarModule,
  MatRadioModule,
  MatProgressSpinnerModule
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
} from "@angular/material";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,

  ],
  exports: [
    MatTableModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    //Used
    MatExpansionModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    FlexLayoutModule,//flex layout
    //backend
    MatBadgeModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatMenuModule,
    MatChipsModule,
    MatDialogModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule, 
    MatTooltipModule,
    MatTabsModule,
    MatSnackBarModule,
    MatRadioModule,
    MatProgressSpinnerModule   
  ]
})
export class MaterialModule { }
