import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from 'src/environments/environment';


environment
@Injectable({
  providedIn: 'root'
})
export class AwsServiceService {

  constructor() { }


  getDocumentWithKey(documentKey: string){
   
    
    AWS.config.update({
      region: 'us-east-2',
      accessKeyId: environment.awsAccessKeyId, //atob(environment.awsAccessKeyId), 
      secretAccessKey: environment.awsSecretAccessKey //atob(environment.awsSecretAccessKey)
    });
    // AWS.config.region = "us-east-2";

    let s3 = new AWS.S3();

    let myBucket = environment.s3BucketName;
   

    const url = s3.getSignedUrl('getObject', {
        Bucket: myBucket,
        Key: documentKey
    })

    // console.log('url', url);
    return url;
  }


}
