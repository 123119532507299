import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RazorPayService {

  urlRoot: string = environment.baseUrl;
  
  constructor(
    private http: HttpClient,
    ) { }



  _insertPaymentSuccessinfo(paymentInfo: any){
    console.log('paymentInfo: ', paymentInfo);
    return this.http.post(this.urlRoot + "razorPayment/add", paymentInfo);
  }

  _insertPaypalPaymentSuccessinfo(paymentInfo: any){
    return this.http.post(this.urlRoot + "payPalPayment/add", paymentInfo);
  }

}
