import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage';
import { ScheduleAppointmentComponent } from './modals/schedule-appointment/schedule-appointment.component';
import { UsernameValidator } from './validators/username-validator';
import { InterceptorService } from './services/interceptor.service';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { SortnamePipe } from './pipes/sortname.pipe';
// import { AuthInterceptor } from './services/authInterceptor.service';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProviderPasswordModal } from 'src/app/modals/provider-password/provider-password.modal';
import { EmrLocationModal } from 'src/app/modals/emr-locations/erm-location.modal';
import { BookAppointmentModal } from 'src/app/modals/book-appointment/book-appointment.modal';
import { ChatGptModal } from './modals/chatgpt/chat-gpt.component';

@NgModule({
  declarations: [
    AppComponent, 
    ScheduleAppointmentComponent, SortnamePipe,
    ProviderPasswordModal, EmrLocationModal,
    BookAppointmentModal,ChatGptModal
  ],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [
    ScheduleAppointmentComponent, ProviderPasswordModal, 
    EmrLocationModal, BookAppointmentModal, ChatGptModal
  ],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    McBreadcrumbsModule.forRoot(),
    AppRoutingModule, 
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MaterialModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    DatePipe,
    CurrencyPipe,
    DocumentViewer,
    UsernameValidator,
    File,
    FileOpener
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
