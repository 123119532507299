import { Notifications } from './../interfaces/notifications.interface';
import { Blog } from './../interfaces/blog.interface';
import { SecondOpinion } from './../interfaces/second-opinion';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { Zone } from './../interfaces/zone';
import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { Storage } from '@ionic/storage';
import { User } from './../interfaces/user';
import { Doctor } from './../interfaces/doctors';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, tap, finalize, shareReplay, take } from 'rxjs/operators'
import { DoctorAvailability } from '../interfaces/doctor-availability';
import { Country } from '../interfaces/country';
import { DatePipe } from '@angular/common';
import { LoadingService } from './loading.service';
import { AwsServiceService } from './aws-service.service';



const TOKEN = "ACCESS_TOKEN";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  // POSTS START
  private posts: Blog[] = [];
  private postsUpdated = new Subject<Blog[]>();
  // POSTS END
  
  _getUserDetails() {
    throw new Error("Method not implemented.");
    console.log("Im Here");
    // localStorage.setItem('currentUser', JSON.stringify({ token: token, name: name }));
  }

  ACCESS_TOKENN: any;
  tokenFromPromise: string;
  userDetails: any;
  
  //url: string = "http://localhost:3000/users";
  //url: string = "http://corvushealthrec.com/api/User/Register";
  urlRoot: string = environment.baseUrl;
  frontendUrl: string = environment.frontendUrl;
  //  "http://vedustestapp-env.eba-vu7gd7md.us-east-2.elasticbeanstalk.com/";
  private user: User;
  doctors;
  ACCESS_TOKEN = 'ACCESS_TOKEN';
  REFRESH_TOKEN = 'REFRESH_TOKEN';
  token;
  blkSesnStaffLink: string = "https://corvushealthrec.bswl.app/session/"; //add to staff append {clientId}
  blkSesnClientLink: string = "https://corvushealthrec.bswl.app/joinsession/"; //add to clients append {}
 
  authenticationState = new BehaviorSubject(false);
  
  // auth_options = {
  //   headers: {
  //     Authorization: localStorage.getItem('TOKEN') ? `Bearer ${JSON.parse(localStorage.getItem("TOKEN"))}` : null
  //   }
  // } 
 
  public sessionId = null;

  // options = {
  //   headers: {
  //     Authorization: `Bearer ${this.token}`,
  //     'Content-Type': 'application/json',
  //     Accept: 'application/json'
  //   }
  // }
  

  


  constructor(
    private http: HttpClient, 
    private storage: Storage,
    private authService: AuthService,
    private alertController: AlertController,
    private toastController: ToastController,
    private datePipe: DatePipe,
    private router: Router,
    private loadingService: LoadingService,
    private awsService: AwsServiceService,
    ) { }

  /**GET DOCTORS API */
  // _getAllDoctors() 
  // {
  //   return this.http.get<Doctor[]>(this.urlRoot + "doctors", this.options)
  //   .pipe(
  //     map(results => {
  //       this.doctors = results;
  //       console.log('DOCTORS: ', results);
  //       return results;
  //     })
  //   ).subscribe();
  // }

  /**Find all doctors*/
  getDoctors(): Observable<Doctor[]>{
      return this.http.get<Doctor[]>(this.urlRoot + "doctors")
      .pipe(
        map((data:Doctor[]) => {

          // console.log(data);
          data.map((innerData: Doctor) => {
          
                innerData.profilePic = innerData.profilePicDocumentKey ? this.awsService.getDocumentWithKey(innerData.profilePicDocumentKey) : null;
                innerData.firstName = innerData.title ? innerData.title + ' ' + innerData.firstName : innerData.firstName;
                // console.log(innerData);
              })

          return data.filter((x: Doctor) => x.subscriptionType !== 'inactive');//Hide inactive doctors
        })
      );
  }

  /**FIND ONE DOCTOR USING doctorId */
  _getDoctorById(doctorId: number){
    return this.http.get<Doctor>(this.urlRoot + "api/doctors/" + doctorId)
    .pipe(
      // tap((n) => console.log(n))
    );
  }

  /**FIND ONE DOCTOR USING doctorId */
  _getDoctorByIdUnAuthenticated(doctorId: number){
    return this.http.get<Doctor>(this.urlRoot + "doctors/" + doctorId)
    .pipe(
      // tap((n) => console.log(n))
    );
  }

  /**UPDATE DOCTOR'S INFORMATION*/
  _updateDoctor(userInfo: any, files: any): Observable<any>
  {
    let formData: FormData = new FormData();

    // formData.append('data', JSON.stringify(userInfo));

    if(files[0]) {
      formData.append('proofOfPractice', files[0]);//Add file to formData
    }

    formData.append('cityOfPractice',userInfo['cityOfPractice']);
    formData.append('state',userInfo['state']);
    formData.append('location',userInfo['location']);
    formData.append('country',userInfo['country']);
    // formData.append('timezone',userInfo['timezone']);
    formData.append('specialization',userInfo['specialization']);
    formData.append('zipcode',userInfo['zip']);
    formData.append('lastName',userInfo['lastName']);
    formData.append('firstName',userInfo['firstName']);
    formData.append('phoneNumber',userInfo['phoneNumber']);
    formData.append('whatsappNumber',userInfo['whatsappNumber']);
    formData.append('countryCallingCode',userInfo['countryCallingCode']);
    formData.append('email',userInfo['email']);
    formData.append('superSpeciality',userInfo['superSpeciality']);
    // formData.append('dateOfBirth',userInfo['dateOfBirth']);
    // formData.append('subscriptionType',userInfo['subscriptionType']);
    // formData.append('role',userInfo['role']);
    formData.append('currency',userInfo['currency']);
    formData.append('doctorCategory',userInfo['doctorCategory']);
    formData.append('prefferedConsultHours',userInfo['prefferedConsultHours']);
    formData.append('presentlyPracticingAt',userInfo['presentlyPracticingAt']);
    formData.append('aboutYourself',userInfo['aboutYourself']);
    formData.append('consultationHrFee',userInfo['consultationHrFee']);
    formData.append('licenceNumber',userInfo['licenceNumber']);
    formData.append('yearsOfExperience',userInfo['yearsOfExperience']);
    
    
    console.log(formData.getAll);

    return this.http.put<any>(this.urlRoot + "api/User/provider/Profile", formData);
  }


  /**Checking email for shouldBeUnique while registering Clients / Adult / User*/
  validateClientsEmail(emailAddress): Observable<boolean>{
    return this.http.get<boolean>(this.urlRoot + "api/User/IsUserExists/" + "adult/" + emailAddress);
    //.map(res => res.json());
  }

  /**Checking email for shouldBeUnique while registering Doctors / Provider*/
  validateDoctorsEmail(emailAddress): Observable<boolean>{
    return this.http.get<boolean>(this.urlRoot + "api/User/IsUserExists/" + "doctor/" + emailAddress);
    //.map(res => res.json());
  }

  /**Checking nuetral email for shouldBeUnique while registering Clients / Doctor / Any User*/
  _validateAnyEmail(emailAddress): Observable<boolean>{
    return this.http.get<boolean>(this.urlRoot + "api/User/IsUserExists/" + "any/" + emailAddress);
    //.map(res => res.json());
  }
  

  /**Find all doctors Appointments*/
  _getDoctorsAppointments(): Observable<any[]>{
    // return this.http.get<any[]>(this.urlRoot + "api/findByDocId/" + id);
    return this.http.get<any[]>(this.urlRoot + "api/findByDocId/8");
 }
 
  /**Find all availability of the doctor with doctorsId*/
  _getDoctorsAvailability(doctorId): Observable<DoctorAvailability[]> {
    return this.http.get<DoctorAvailability[]>(this.urlRoot + "availability/" + doctorId);
  }


  _getLoggedInDoctorDetailsIntoStorage(): Observable<Doctor>{
    return this.http.get<Doctor>(this.urlRoot + "api/User/GetDoctorProfile");
  }

  _getLoggedInClientsDetailsIntoStorage(){
    return this.http.get(this.urlRoot + "api/User/GetUserProfile");
  }

  _getLoggedInChildDetailsIntoStorage(adultId : number){
    return this.http.get(this.urlRoot + "api/User/GetChildProfile/" + adultId );
  }


  /**Get all BlinkSession Providers / Staff / Doctor */
  _getBlinkSessionStaffs(): Observable<any[]>{
    return this.http.get<any[]>(this.urlRoot + "blinkSession/staff");
  }

  /**Find all availability of the Staff with doctorsId - UI Consultation services*/
  _getStaffsAvailability(doctorId, doctorsTimeZone): Observable<any[]> {
    const params = new HttpParams()
    .set("timezone", doctorsTimeZone)
    .set("objects", "clients,staff")
    .set("future", "1");

    // const fullURL = `${this.urlRoot}blinkSession/events/staff/${doctorId}?${params.toString()}`;
    // console.log({ fullURL });

    return this.http.get<any[]>(this.urlRoot + "blinkSession/events/staff/" + doctorId, {params})}
    // .pipe(
    //   map((res: any) => {
    //     res.data.title === "**available SLot**";
    //   })
    // );

  _getBlinkSessionEventsByProvider(){
    return this.http.get("https://api.blinksession.com/v1/events/staff/7988?timezone=Asia/Kolkata&&objects=clients,staff");
  }

  /**THIS FUNCTION GETS THE MINUTES BETWEEN TWO DATES AND RETURNS MINUTES - START and END DATETIME */
  getMinutesBetweenDates(startDate: any, endDate: any)
  { 
    let sDate: any = new Date(startDate);
    let eDate: any =  new Date(endDate);
    let diff: any;
    console.log("DATES:" + startDate, endDate);
    console.log("DATES SUBTRACTED:" + (eDate - sDate));
    // diff = endDate.getTime() - startDate.getTime(); 
    diff = eDate.getTime() - sDate.getTime(); 
    return (diff / 60000); 
  }

  /**THIS CONVERTS DATE TO ANY DESIRABLE FORMAT - https://angular.io/api/common/DatePipe*/
  _convertDateFormatTo(fromDate: string, dateFormat: any)
  {
    // https://angular.io/api/common/DatePipe
    // console.log("Date: before conversion: " + fromDate);
    let formatedDate: string = this.datePipe.transform(fromDate, dateFormat);
    // console.log("Date: After conversion: " + formatedDate);
    return formatedDate;
    
  }

  // _getLoggedInDoctorsAvailability(): Observable<DoctorAvailability[]> {
  //   const zaga = this.urlRoot + "availabilityDto/";
  //   console.log("zaga: " + zaga);
  //   return this.http.get<DoctorAvailability[]>(zaga);
  // }

  // _getLoggedInDoctorsAvailability() {
  //   const zaga = this.urlRoot + "availabilityDto/";
  //   console.log("zaga: " + zaga);
  //   return this.http.get("http://localhost:3000/schedule");
  // }

  /**Add Patients availability */
  _addAppointment(postedData){
    return this.http.post(this.urlRoot + "blinkSession/events", postedData)
    .pipe(
      // tap(_ => this.loadingService.loadingOn()),
      // finalize(() => this.loadingService.loadingOff())
    );
    // return this.http.post(this.urlRoot + "api/appointments", postedData);
  }
  

  _getUsers()
  {
    
  }

  /** Add a new client / user */
  _addUser(postedUser: User): Observable<any>
  { 
    //var reqHeader = new HttpHeaders({'no-auth': 'True'});
    return this.http.post<User>(this.urlRoot + "api/User/Register", postedUser);
  }

  /**Get user Ip Address */
  _getLocationByIpAddress(): Observable<any>{
    // return this.http.get<any>('http://ip-api.com/json'); https://extreme-ip-lookup.com/json/
    return this.http.get<any>('https://ipapi.co/json');
    // https://blog.logrocket.com/detect-location-and-local-timezone-of-users-in-javascript-3d9523c011b9/
  }

  /** ADD PROVIDER / DOCTOR */
  // _addProvider(userInfo: Doctor): Observable<Doctor>
  _addProvider(userInfo: any, files: any): Observable<any>
  {
    //var reqHeader = new HttpHeaders({'no-auth': 'True'});
    // return this.http.post<Doctor>(this.urlRoot + "api/User/provider/Register", userInfo)
    let formData: FormData = new FormData();

    

    if(files[0]){//Both files are uploaded
      //formData.append('govtId', files[0]);//Add file to formData
      // formData.append('pgCert', files[0]);//Add file to formData
      formData.append('mbbsCert', files[0]);//Add file to formData

      //MAKE govtId null
      // formData.append('govtId', null);//Add file to formData
      // formData.append('govtId', files['govtId']);//Add file to formData
      // formData.append('pgCert', files['pgCert']);//Add file to formData
      // formData.append('mbbsCert', files['mbbsCert']);//Add file to formData
    }else if(files[1]){//Only first file is uploaded
      //formData.append('govtId', files[0]);//Add file to formData
      formData.append('pgCert', files[1]);
      // formData.append('pgCert', files[0]);//Add file to formData
    }
    else{
      // formData.append('govtId', files[0]);//Add file to formData
      // formData.append('proofOfPractice', files[0]);//Add file to formData
      console.log('File Notification: No file uploaded slot');
    }
    // else if(!files[0] && !files[1]){
    //   formData.append('pgCert', files[0]);//Add file to formData
    // }
    
    formData.append('data', JSON.stringify(userInfo));

    
    formData.append('cityOfPractice',userInfo['cityOfPractice']);
    formData.append('state',userInfo['state']);
    formData.append('location',userInfo['location']);
    formData.append('country',userInfo['country']);
    formData.append('timezone',userInfo['timezone']);
    formData.append('specialization',userInfo['specialization']);
    formData.append('zip',userInfo['zip']);
    formData.append('licenceNumber',userInfo['licenceNumber']);
    formData.append('consultationHrFee',userInfo['consultationHrFee']);
    formData.append('aboutYourself',userInfo['aboutYourself']);
    formData.append('prefferedConsultHours',userInfo['prefferedConsultHours']);
    formData.append('presentlyPracticingAt',userInfo['presentlyPracticingAt']);
    formData.append('doctorCategory',userInfo['doctorCategory']);
    formData.append('role',userInfo['role']);
    formData.append('subscriptionType',userInfo['subscriptionType']);
    formData.append('password',userInfo['password']);
    formData.append('username',userInfo['username']);
    formData.append('email',userInfo['email']);
    formData.append('phoneNumber',userInfo['phoneNumber']);
    formData.append('whatsappNumber',userInfo['whatsappNumber']);
    formData.append('lastName',userInfo['lastName']);
    formData.append('firstName',userInfo['firstName']);
    formData.append('countryCallingCode',userInfo['countryCallingCode']);
    formData.append('currency',userInfo['currency']);
    formData.append('gender',userInfo['gender']);
    formData.append('yearsOfExperience',userInfo['yearsOfExperience']);
    
    return this.http.post<any>(this.urlRoot + "api/User/provider/Register", formData);
  }


  /** ADD INTERNATIONAL PROVIDER / DOCTOR */
  _addInterProvider(userInfo: any, files: any): Observable<any>
  {
    //var reqHeader = new HttpHeaders({'no-auth': 'True'});
    // return this.http.post<Doctor>(this.urlRoot + "api/User/provider/Register", userInfo)
    let formData: FormData = new FormData();

    

    // if(files[1]){
    //   formData.append('govtId', files[0]);//Add file to formData
    //   formData.append('pgCert', files[1]);//Add file to formData
    //   formData.append('mbbsCert', files[2]);//Add file to formData
    // } else{
    //   formData.append('proofOfPractice', files[0]);//Add file to formData
    // }
    
    formData.append('data', JSON.stringify(userInfo));

    // formData.append('proofOfPractice', userInfo['proofOfPractice']);//Add file to formData
    // formData.append('proofOfPractice', files);//Add file to formData
    if(files[0]) {
      formData.append('proofOfPractice', files[0]);//Add file to formData
    }
    formData.append('cityOfPractice',userInfo['cityOfPractice']);
    formData.append('state',userInfo['state']);
    formData.append('location',userInfo['location']);
    formData.append('country',userInfo['country']);
    formData.append('timezone',userInfo['timezone']);
    formData.append('specialization',userInfo['specialization']);
    formData.append('zip',userInfo['zip']);
    formData.append('lastName',userInfo['lastName']);
    formData.append('firstName',userInfo['firstName']);
    formData.append('phoneNumber',userInfo['phoneNumber']);
    formData.append('countryCallingCode',userInfo['countryCallingCode']);
    formData.append('email',userInfo['email']);
    formData.append('password',userInfo['password']);
    formData.append('username',userInfo['username']);
    formData.append('subscriptionType',userInfo['subscriptionType']);
    formData.append('role',userInfo['role']);
    formData.append('currency',userInfo['currency']);
    formData.append('doctorCategory',userInfo['doctorCategory']);
    formData.append('prefferedConsultHours',userInfo['prefferedConsultHours']);
    formData.append('presentlyPracticingAt',userInfo['presentlyPracticingAt']);
    formData.append('aboutYourself',userInfo['aboutYourself']);
    formData.append('consultationHrFee',userInfo['consultationHrFee']);
    formData.append('licenceNumber',userInfo['licenceNumber']);
    formData.append('gender',userInfo['gender']);
    
    
    console.log(formData.getAll);

    return this.http.post<any>(this.urlRoot + "api/User/provider/Register", formData);
  }

  /** GET ALL COUNTRIES */
  _findCountries(): Observable<Country[]>{
    return this.http.get<Country[]>(this.urlRoot + "api/Countries/GetAll")
    .pipe(
      shareReplay()
    );
  }

  /** GET ALL TIME ZONES */
  _findTimeZones(): Observable<Zone[]>{
    return this.http.get<Zone[]>(this.urlRoot + "zones/GetAll")
    .pipe(
      shareReplay()
    );
  }

  /** GET TIME ZONES BY COUNTRY */
  _findTimeZonesByCountry(country): Observable<Zone[]>{
    return this.http.get<Zone[]>(this.urlRoot + "zones/GetByCountry/" + country)
    .pipe(
      shareReplay()
    );
  }

  /** GET CALLING CODE BY COUNTRY */
  _findCallingCodeByCountry(country): Observable<any>{
    return this.http.get<any>(this.urlRoot + "api/Countries/GetByCountryName/" + country)
    .pipe(
      //shareReplay()
      map((result: any) => result['callingCode']),
    );
  }

  
  /**START DOCTORS APPOINTMENT HERE */
  //_getDoctorsAppointments



  /**START INTERCEPTOR HERE */
 

  getToken() {
    return this.storage.get(this.ACCESS_TOKEN);
  }

  _logoutUser(){
    this.authService.logout()
  }
  

  /**ALERT CONTROLLER */
  async presentAlertApi(header: string, message: string, button?: string) {
    if(!button){
      button = 'OK';
    }
    const alert = await this.alertController.create({
      header: header,
      message: message,
      
      buttons: [button]
      // header: 'Appointment Status',
      // subHeader: 'Subtitle',
      // message: 'Appointment Successfully added.',
      // buttons: ['OK']
    });

    await alert.present();
  }

  /**ALERT CONTROLLER WITH HANDLER ACTION LINK*/
  async presentAlertApiHandler(header: string, message: string, button?: string, handlerLink?: string) {
    if(!button){
      button = 'OK';
    }
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
          {
            text: button,
            handler: () => {
              this.router.navigateByUrl(handlerLink)
              // window.location.href = (handlerLink);
            }
          }
        ]
    });

    await alert.present();
  }

  /**TOAST CONTROLLER */
  async presentToast(message: string, timer:number) {
    const toast = await this.toastController.create({
      message: message,
      duration: timer,
      position: 'bottom',
      color: 'light',
    });
    toast.present();
  }

    /**Get the Events of a Provider - Provider Availability*/
  _getProviderEvents(staffId:any, timezone: any){
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "clients,staff");
    // .set("future", "1");

      // const fullURL = `${this.urlRoot}blinkSession/events/staff/${staffId}?${params.toString()}`;
      // console.log({ fullURL });

    return this.http.get<any[]>(this.urlRoot + "blinkSession/events/staff/" + staffId, {params})
    .pipe(
      // take(3)
      shareReplay()
    );
  }

  /**Get the Events of a Client - Client Availability*/
  _getClientEvents(clientId:any, timezone: any){
    //Set parmeters
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "staff,clients");

    // const fullURL = `${this.urlRoot}blinkSession/events/client/${clientId}?${params.toString()}`;
    // console.log({ fullURL });

    //HTTP call
    return this.http.get<any[]>(this.urlRoot + "blinkSession/events/client/" + clientId, {params});
  }

  /**THIS METHOD GETS PROVIDERS FROM LOCAL STORAGE - Note you need to declare a variable userDetails to use this*/
  _getProviderFromStorage() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  /**THIS METHOD GETS ADULT / USER FROM LOCAL STORAGE*/
  _getClientFromStorage(){
    let loggedInUserDetails = localStorage.getItem('currentUser');
    this.authService._getLoggedInUserFromStorage(loggedInUserDetails);
    return JSON.parse(loggedInUserDetails);
  }

  _getChildClientFromStorage(){
    let loggedInUserDetails = sessionStorage.getItem('child');
    // this.authService._getLoggedInUserFromStorage(loggedInUserDetails);
    return JSON.parse(loggedInUserDetails);
  }

  /**THIS METHOD GETS PROVIDERS FROM BLINK SESSION */
  _getProviderFromBlinkSession(staffId:any, timezone: any) {
    let link: string = this.urlRoot + "blinkSession/staff/" + staffId + "?timezone=" + timezone + "&objects=clients,staff";
    //console.log (link);
    return this.http.get(link);
  }

  

  

  /**DOWNLOAD DOCUMENT */
  _downloadDocumentFromS3(){
    return this.http.get<any>(this.urlRoot + "api/User/DownloadDocument");
  }

  /**CONTACT FORM */
  _sendContactForm(contactFormContent: any): Observable<any>{
    return this.http.post<any>(this.urlRoot + "contact/add", contactFormContent);
  }

  /**PRESCRIPTION FORM SUBMIT - PROVIDER */
  _sendPrescription(prescriptionFormContent: any): Observable<any>{
   let formData: FormData = new FormData();

    // let documentFile: File = prescriptionFormContent.document; 
    // let convertedBlobToFile: File = new File([prescriptionFormContent.document], "prescription.pdf", { type: prescriptionFormContent.document.type });
    console.log("convertedBlobToFile: ", prescriptionFormContent.document);

    formData.append('diagnosis',prescriptionFormContent['diagnosis']);
    formData.append('investigations',prescriptionFormContent['investigations']);
    formData.append('prescription',prescriptionFormContent['prescription']);
    formData.append('specialInstructions',prescriptionFormContent['specialInstructions']);
    formData.append('signature',prescriptionFormContent['signature']);
    formData.append('document',prescriptionFormContent['document']);
    formData.append('adultId',prescriptionFormContent['clientId']);
    formData.append('eventId',prescriptionFormContent['eventId']);
    
    return this.http.post<any>(this.urlRoot + "api/Prescription/add", formData);
  }

  /**GET ALL PRESCRIPTIONS FOR - USER */
  _getAllPatientPrescriptions(): Observable<any>{
    return this.http.get<any>(this.urlRoot + "api/get/adult/");
  }

  /**GET PRESCRIPTION BY EVENT ID FOR - PROVIDER */
  _getDoctorsPrescriptionByEventId(eventId: any): Observable<any>{
    return this.http.get<any>(this.urlRoot + "api/Prescription/get/" + eventId)
    .pipe(
      map((data:any[]) => {

        // console.log(data);
        data.map((innerData: any) => {
              innerData.document = innerData.documentKey ? this.awsService.getDocumentWithKey(innerData.documentKey) : null;
            })

        return data;
      })
    );
  }

   /**GET Doctors and Client Object BY Client ID FOR - PROVIDER */
  _getDoctorsAndClientObjectByClientId(clientId){
    return this.http.get<any>(this.urlRoot + "api/Prescription/get/doctor/adult/" + clientId);
  }

  /**GET PRESCRIPTION BY EVENT ID FOR - CLIENT */
  _getClientsPrescriptionByEventId(eventId: any): Observable<any>{
    return this.http.get<any>(this.urlRoot + "api/Prescription/get/" + eventId)
    .pipe(
      map((data:any[]) => {

        // console.log(data);
        data.map((innerData: any) => {
              innerData.document = innerData.documentKey ? this.awsService.getDocumentWithKey(innerData.documentKey) : null;
            })

        return data;
      })
    );
  }

  /**GET ALL PRESCRIPTIONS FOR - PROVIDER */
  _getAllProvidersPrescriptions(): Observable<any>{
    return this.http.get<any>(this.urlRoot + "api/Prescription/get/doctor");
  }

  

  /**GET ALL PRESCRIPTIONS FOR - USER */
  _getAllPatientPrescriptionByEventId(eventId: any): Observable<any>{
    return this.http.get<any>(this.urlRoot + "api/Prescription/get/" + eventId);
  }

  

  //Convert blob to file
  blobToFile(theBlob, fileName){      
    // let convertedBlobToFile: File = new File([prescriptionFormContent.document], "prescription.pdf", { type: prescriptionFormContent.document.type }); 
    return new File([theBlob], fileName);
    // var fileOfBlob = new File([blob], 'aFileName.json');
  }

  /** GET PRESCRIPTIONS IN PROVIDER */
  _getPrescriptionsForProvider(){
    return this.http.get(this.urlRoot + '/get/adult')
    .pipe(
      map((data:any[]) => {

        // console.log(data);
        data.map((innerData: any) => {
              innerData.blogPic = innerData.blogPicDocumentKey ? this.awsService.getDocumentWithKey(innerData.blogPicDocumentKey) : null;
            })

        return data;
      })
    );
  }

  /** GET PRESCRIPTIONS IN PATIENT */
  _getPrescriptionsForPatient(){
    return this.http.get(this.urlRoot + '/get/adult')
    .pipe(
      map((data:any[]) => {

        // console.log(data);
        data.map((innerData: any) => {
              innerData.blogPic = innerData.blogPicDocumentKey ? this.awsService.getDocumentWithKey(innerData.blogPicDocumentKey) : null;
            })

        return data;
      })
    );
  }

  /**_Register Provider from blink session Into System */
  _registerProviderIntoSystem(postedUser: Doctor): Observable<Doctor>{
    return this.http.post<Doctor>(this.urlRoot + "api/User/provider/RegisterInSystem", postedUser);
    // .pipe(
    //     finalize(() => this.loadingService.loadingOff())
    //   );
  }

  /**SECOND OPINION FORM */
  _sendSecondOpinionForm(contactFormContent: any): Observable<SecondOpinion>{
    return this.http.post<SecondOpinion>(this.urlRoot + "secondOpinion/add", contactFormContent);
  }

  /**CURENCY CONVERTER REMOVER*/
  _currencyConverterRemover(amount: any, currencySymbol: string){
      // let currencyFormattedValue = this.currencyPipe.transform(amount);
      console.log(amount);
      let currencySymbolSliced;
      let curencyRemoverFn;
      if(amount.includes('₹') || amount.includes('$')) {
        currencySymbolSliced = amount.slice(0, 1) ? amount.slice(0, 1) : currencySymbol;
        amount = amount.replace(currencySymbolSliced, "");
      }
      curencyRemoverFn = Number(amount).toFixed(2).replace(".", "");
      console.log(parseInt(curencyRemoverFn));
      return parseInt(curencyRemoverFn);
  }

  _currencyConverterRemoverPaypal(amount: any, currencySymbol: string){
      // let currencyFormattedValue = this.currencyPipe.transform(amount);
      console.log(amount);

      let currencySymbolSliced = amount.slice(0, 1) ? amount.slice(0, 1) : currencySymbol;

      let curencyRemoverFn = Number(amount.replace(currencySymbolSliced, "")).toFixed(2);
      console.log(curencyRemoverFn);
      return curencyRemoverFn;
  }


  /**POSTS / BLOG*/
  // _getBlogPosts(){
  //   return this.http.get('https://jsonplaceholder.typicode.com/posts');
  // }

  // _getBlogDetails(postId){
  //   return this.http.get('https://jsonplaceholder.typicode.com/posts/' + postId);
  // }

  _getBlogPosts(){
    return this.http.get(this.urlRoot + 'blogPost/getAll')
    .pipe(
      map((data:any[]) => {

        // console.log(data);
        data.map((innerData: any) => {
              innerData.blogPic = innerData.blogPicDocumentKey ? this.awsService.getDocumentWithKey(innerData.blogPicDocumentKey) : null;
            })

        return data;
      })
    );
  }

  _getBlogDetails(postId){
    return this.http.get(this.urlRoot + 'blogPost/get/' + postId)
    .pipe(
      map((data:any) => {
        data.blogPic = data.blogPicDocumentKey ? this.awsService.getDocumentWithKey(data.blogPicDocumentKey) : null;
        return data;
      })
    );
  }

  

  getPostUpdateListener() {
    return this.postsUpdated.asObservable();
  }

  _sortArrayOfObjects(arrayObj: Array<any>, sortTerm: any){
    return arrayObj.sort((a,b) => {
      return b.sortTerm - a.sortTerm;
    });
  }


  /**GET NOTIFICATIONS FOR LOGGED IN USER */
  _getProviderNotifications() : Observable<Notifications[]>
  {
    return this.http.get<Notifications[]>(this.urlRoot + "notifications/user")
    .pipe(
      // tap(_ => console.log(_))
      // map(results => {
      //   this.doctors = results;
      //   console.log('DOCTORS: ', results);
      //   return results;
      // })
    );
  }

  /**SEND DOCUMENT FROM CLIENT TO DOCTOR */
  sendDocument(obj){
    return this.http.post(this.urlRoot + "documents/send", obj);
  }

  adultAddDocument(body: any, userUuid){
    let params = new HttpParams()
    .set("documentKey",  body.documentId)
    .set("userUuid", userUuid);

    return this.http.post(this.urlRoot + `api/User/Adult/SendDocumentToDoctor`, body, {params});
  }
  
  validateCouponCode(couponCode: string, doctorsId: any){
    const body = {
      couponCode: couponCode,
      doctorsId: doctorsId
    }
    return this.http.post(`${this.urlRoot}api/Coupon/apply`, body);
  }

  getAllClinicLocations() {
    return this.http.get<any>(this.urlRoot + "bahmni/clinic/getAll")
    .pipe(
      map((data:any[]) => {
        data.map((innerData: any) => {
              innerData.logoFile = innerData.logoFileDocumentKey ? this.awsService.getDocumentWithKey(innerData.logoFileDocumentKey) : null;
            })
        return data;
      })
    );
  }

  getClinicByLocationUuid(locationUuid: string) {
    return this.http.get<any>(this.urlRoot + "bahmni/clinic/getByLocationUuid/" + locationUuid);
  }

  getCountries() {
    return this.http.get<any>("assets/json/countries.json");
  }

  getStates() {
    let body = {"country" : "India"};
    return this.http.post<any>("https://countriesnow.space/api/v0.1/countries/states", body);
  }

  getCities(state) {
    let body = {"country" : "India", "state" : state};
    return this.http.post<any>("https://countriesnow.space/api/v0.1/countries/state/cities", body);
  }

  addClinic(body) {
    this.sessionIdHandle();
    return this.http.post(this.urlRoot + `bahmni/provider/add?jSessionId=`+ this.sessionId, body);
  }

  // Search Provider (Doctor) 
  searchPorvider(searchText) {
    this.sessionIdHandle();
    let params = new HttpParams()
    .set("name",  searchText)
    return this.http.get(this.urlRoot + `bahmni/provider/search?jSessionId=`+ this.sessionId, {params}); 
  }

  getClientsByProvider() {
    return this.http.get(this.urlRoot + `bahmni/patient/fetchByProvider` + '?jSessionId='+ this.sessionId);
  }

  getProvidersByLocation() {
    let locationUuid = localStorage.getItem('SESSION') ? JSON.parse(localStorage.getItem('SESSION')).locationUuid : null;
    return this.http.get(this.urlRoot + `bahmni/provider/fetchByLocation/` + locationUuid + '?jSessionId='+ this.sessionId);
  }

  getAdultDetails(body){
    let params = new HttpParams()
    .set('customAttribute', body.customAttribute)
    .set('firstName', body.firstName)
    .set('lastName', body.lastName)
    .set('dateOfBirth', body.dateOfBirth)
    return this.http.get(this.urlRoot + 'api/User/adult/getDetails', {params})
  }

  // Search Patient By Id
  searchPatientById(data) {
    this.sessionIdHandle();
     let params = new HttpParams()
    .set('identifierTextToBeSearched', data)
    return this.http.get(this.urlRoot + `bahmni/patient/searchById?jSessionId=`+ this.sessionId, {params} ); 
  }
  
  // Get Patient Visit Summary
  getPatientVisitSummary(appointmentUuid) {
    //let patientUuid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).bahmniUuid : null;
    return this.http.get(this.urlRoot + `bahmni/patient/visitSummary/${appointmentUuid}`); 
  }
  
  // Search Appointment Service
  searchAppointmentService(searchText, locationText, providerUuid) {
    this.sessionIdHandle();
     let params = new HttpParams()
    .set("searchText",  searchText)
    .set("locationText",  locationText)
    .set("providerUuid", providerUuid);
    return this.http.get(this.urlRoot + `bahmni/appointment/service/search?jSessionId=`+ this.sessionId, {params} ); 
  }

  appointmentBooking(data) {
    this.sessionIdHandle();
    let params = new HttpParams()
    .set("paymentType",  data.paymentType || null)
    .set("paymentId",  data.paymentId || null)
    .set("providerName",  data.providerName)
    //.set("providerUuid",  data. providerUuid)
    return this.http.post(this.urlRoot + 'bahmni/appointment/booking?jSessionId='+ this.sessionId, data.appointment, {params}); 
  }


  linkDoctor(providerUuid:string, clinicLocationUuid:string){
    return this.http.post(this.urlRoot + 'bahmni/provider/' +providerUuid+'/addToClinicLocation/'+clinicLocationUuid+'?jSessionId='+ this.sessionId, {}); 
  }

  getLocationMapping(providerUuid:string){
    return this.http.get(this.urlRoot + 'bahmni/provider/locationMapping/' +providerUuid+'?jSessionId='+ this.sessionId); 
  }

  authenticateBahmniUser(body){
    var auth_options = {
      headers: {
        Authorization: localStorage.getItem('TOKEN') ? `Bearer ${JSON.parse(localStorage.getItem("TOKEN"))}` : null
      }
    }
    return this.http.post(this.urlRoot + 'bahmni/user/authenticate?locationUuid='+ body.locationUuid + '&password='+ body.password, {}, auth_options)
  }

  _addPatient(body) {
    return this.http.post(this.urlRoot + 'api/User/Adult/Register', body); 
  }

  getAppointmentServiceSlot(serviceUuid, appointmentBookableDate) {
    this.sessionIdHandle();
     let params = new HttpParams()
    .set("serviceUuid", serviceUuid)
    .set("appointmentBookableDate", appointmentBookableDate);
    return this.http.get(this.urlRoot + `bahmni/appointment/service/availableSlotDetails?jSessionId=`+ this.sessionId, {params} ); 
  }

  getProviderLocation() {
    this.sessionIdHandle();
    var auth_options = {
      headers: {
        Authorization: localStorage.getItem('TOKEN') ? `Bearer ${JSON.parse(localStorage.getItem("TOKEN"))}` : null
      }
    }
    return this.http.get(this.urlRoot + `bahmni/clinic/getByProvider?jSessionId=`+ this.sessionId, auth_options);
  }

  sessionIdHandle() {
    this.sessionId = localStorage.getItem('SESSION') ? JSON.parse(localStorage.getItem('SESSION')).sessionId : null;
  }

  getProvidersByClient() {
    let patientUuid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).bahmniUuid : null;
    return this.http.get(this.urlRoot + `bahmni/provider/fetchByClient/`+ patientUuid +'?jSessionId='+ this.sessionId);
  }

  getAppointments() {
    let patientUuid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).bahmniUuid : null;
    return this.http.get(this.urlRoot + `bahmni/appointment/patient/`+ patientUuid +'?jSessionId='+ this.sessionId);
  }
  getAppointmentsChild() {
    let patientUuid = sessionStorage.getItem('child') ? JSON.parse(sessionStorage.getItem('child')).bahmniUuid : null;
    return this.http.get(this.urlRoot + `bahmni/appointment/patient/`+ patientUuid +'?jSessionId='+ this.sessionId);
  }

  getAppointmentsByProvider(providerUuid, locationUuid) {
    let params = new HttpParams()
    .set("includeCancelled", "true")
    .set("fromDate", "2022-01-01")
    .set("locationUuid", locationUuid);
    // let providerUuid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).bahmniUuid : null;
    return this.http.post(this.urlRoot + `bahmni/appointment/provider/`+ providerUuid +'?jSessionId='+ this.sessionId, null, {params});
  }

  cancelAppointment(appointmentUuid, startDateTime, locationUUid, providerName) {
    let body = { 
      "toStatus": "Cancelled"
    };
    let params = new HttpParams()
    .set("locationUuid", locationUUid)
    .set("providerName", providerName)
    .set("startDateTime", startDateTime);
    return this.http.post(this.urlRoot + `bahmni/appointment/statusChange/`+ appointmentUuid +'?jSessionId='+ this.sessionId, body, {params});
  }

  deleteSession() {
    return this.http.delete(this.urlRoot + `bahmni/user/deleteSession`);
  }

  getSpecialitiesList() {
    return this.http.get(this.urlRoot + `bahmni/appointment/specialitiesList`);
  }

  _getDoctorByEmail(doctorEmail: string){
    return this.http.get<Doctor>(this.urlRoot + "api/doctors/email/" + doctorEmail)
    .pipe(
      // tap((n) => console.log(n))
    );
  }

  getAllLocation(){
    
    return this.http.get(this.urlRoot + `bahmni/appointment/locationList`);
    
  }

  getPhoneNumberById(patientId: string) {
    return this.http.get<any>(this.urlRoot + 'bahmni/patient/patientMobileNumber?patientId=' + patientId);
  }


  getSevicesList() {
    return this.http.get(this.urlRoot + 'api/servicetag/serviceTagsList');
  }

  getServices() {
    return this.http.get(this.urlRoot + 'api/servicetag/getAll');
  }

  getAllChildren() {
    return this.http.get<any>(this.urlRoot + "api/User/GetChildrenByParent")
    .pipe(
      map((data:any[]) => {
        data.map((innerData: any) => {
              innerData.logoFile = innerData.logoFileDocumentKey ? this.awsService.getDocumentWithKey(innerData.logoFileDocumentKey) : null;
            })
        return data;
      })
    );
  }

  addChild(data:any){
    return this.http.post(this.urlRoot + "api/User/RegisterChild",data).pipe(map(res=>res))
  }
  
}