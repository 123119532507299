import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';

/**
 * THIS MODULE USES LAZY LOADING, IT LAZY LOADS HOME AND DASHBOARD MODULES
 */
const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: '', redirectTo: 'home', pathMatch: 'full' }, <-- Switch this back to default routing when done with backend
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    data: {
      // Uses static text (Home)
      breadcrumbs: 'HOME' 
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    data: {
      // Uses static text (Home)
      breadcrumbs: 'HOME'  
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    data: {
      // Uses static text (Home)
      breadcrumbs: 'DASHBOARD' 
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'clinic',
    loadChildren: () => import('./clinic/clinic.module').then( m => m.ClinicModule)
  },
  {
    path: 'providers',
    loadChildren: () => import('./providers/providers.module').then( m => m.ProvidersPageModule),
    //canActivate: [AuthGuard],
    data: {
      // Uses static text (Home)
      breadcrumbs: 'PROVIDERS' 
    }
  },
  {
    path: 'dashboard/providers',
    loadChildren: () => import('./dashboard-providers-dashboard/dashboard-providers-dashboard.module').then( m => m.DashboardProvidersDashboardPageModule),
    data: {
      // Uses static text (Home)
      breadcrumbs: 'DASHBOARD / PROVIDERS' 
    }
  },
  {
    path: 'doctors',
    loadChildren: () => import('./doctors/doctors.module').then( m => m.DoctorsPageModule)
  },
  {
    path: 'clinic-doctor',
    loadChildren: () => import('./clinic-doctors/clinic-doctors.module').then( m => m.ClinicDoctorsPageModule)
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./home/forbidden/forbidden.module').then( m => m.ForbiddenPageModule),
    //canActivate: [AuthGuard],
    data: {
      breadcrumbs: 'Forbidden'  
    }
  },
  {
    path: 'not-found',
    loadChildren: () => import('./not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
  
  {
    path: 'child/:id',
    loadChildren: () => import('./child/child.module').then( m => m.ChildPageModule)
  },
 
  // {
  //   path: 'dashboard-providers-dashboard',
  //   loadChildren: () => import('./dashboard-providers-dashboard/dashboard-providers-dashboard.module').then( m => m.DashboardProvidersDashboardPageModule)
  // },

  
  {
    path: '**', redirectTo:'not-found', pathMatch:'full'
  },

  
];

@NgModule({
  imports: [
    //RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterModule.forRoot(routes),
    McBreadcrumbsModule.forRoot()
    
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
