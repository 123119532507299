export const environment = {
  production: true,
  name: '(DEV)',
  baseUrl: 'https://dev-backendebs.vedushealth.com/',
  paypalClientId: 'AVRq5-aGr_t-_o5qiffyP6fnBnsuNcBoF8UtkainwORCSDhMA_ejbvyZySQbXKu-aipNTToYhB0m-jJB',
  razorpayClientId: 'rzp_test_fUtGBQueO8lLFg',
  awsAccessKeyId: 'AKIAYLRNGYJ6SSLUFLPD',
  awsSecretAccessKey: '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',
  s3BucketName: 'vedus-uploads-dev',
  frontendUrl: 'https://dev.vedushealth.com/',
  emrUrl: 'https://emr-dev.vedushealth.com/'
};
