import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T>{
    return undefined;
  }

  constructor(private loadingController: LoadingController) { }

  loadingOn(loadingMessage?: string){
    //this.loadingSubject.next(true);

    if(!loadingMessage){
      loadingMessage = 'Please wait...';
    }
    this.presentLoading(loadingMessage);
  }

  async loadingOff(){
    //this.loadingController.dismiss();
    this.loadingSubject.next(false);
    // return await this.loadingController.dismiss().then(() => console.log('dismissed'));
    return await this.loadingController.dismiss().catch(() => {});
  }

  
  // async presentLoading_OLD(loadingMessage?: string) {
  //   this.loadingSubject.next(true);
  //   const loading = await this.loadingController.create({
  //     cssClass: 'my-custom-class',
  //     message: loadingMessage,
  //     backdropDismiss: true
  //     //duration: 2000
  //   });
  //   await loading.present();

  //   const { role, data } = await loading.onDidDismiss();
  //   console.log('Loading dismissed!');
  // }



  async presentLoading(loadingMessage?: string) {
    this.loadingSubject.next(true);
    return await this.loadingController.create({
      // cssClass: 'my-custom-class',
      message: loadingMessage,
      backdropDismiss: true
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.loading$) {
          console.log(this.loading$ + " " + new Date());
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }




}
