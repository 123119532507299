import { staff } from './../interfaces/blink-session-staff-event';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaffEvents } from '../interfaces/blink-session-staff-event';
import { environment } from 'src/environments/environment';
import { map, shareReplay, finalize, tap, filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlinkSessionService {

  //DEFINE VARIABLES
  events$: Observable<StaffEvents>;
  urlRoot: string = environment.baseUrl;

  constructor(
    private http: HttpClient, 
    private loadingService: LoadingService
    ) { }
  
   /**Get the Events of a Provider - Provider Availability*/
   _getClientEvents(clientId:any, timezone: any) : Observable<StaffEvents[]>{
    this.loadingService.loadingOn("Loading events");
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "eventsAll,linkedClientLogins");
    // .set("objects", "eventsAll,linkedClientLogins");
    // .set("future", "1");
    // http://localhost:8080/blinkSession/clients/8268?timezone=America/Antigua&objects=eventsAll,linkedClientLogins
    return this.http.get<StaffEvents[]>(this.urlRoot + "blinkSession/clients/" + clientId, {params})
    .pipe(
      tap((n) => console.log(n)),
      map((result: StaffEvents[]) => {
        
        // let events = result['data'].map(result => result);
        let eventsObj = result['data'].map(result => result);
        // let staff = events.map(event => event.staff[0]);
        // console.log(staff);

        // let user = staff.map((staffs : staff) => staffs.user);
        // console.log(user);

        // let userDetails = user.map(x => x);
        // console.log(userDetails);

   
        // let combinedArray = events.concat(user);
        return eventsObj;

        
      }),//ed map
      // filter((x: StaffEvents) => )
      tap((n) => console.log(n)),
      map(user => {
        return user;
      }),
      shareReplay(),
      finalize(() => {
        //I HAVE FINALIZED - ANY INSTRUCTIONS?
        this.loadingService.loadingOff();
      })
    );
  }

  _getClientEvents_OLD(clientId:any, timezone: any) : Observable<StaffEvents[]>{
    this.loadingService.loadingOn("Loading events");
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "clients,staff");
    // .set("future", "1");

    const fullURL = `${this.urlRoot}blinkSession/events/client/${clientId}?${params.toString()}`;
    console.log({ fullURL });

    return this.http.get<StaffEvents[]>(this.urlRoot + "blinkSession/events/client/" + clientId, {params})
    .pipe(
      tap((n) => console.log(n)),
      map((result: StaffEvents[]) => {
        
        let events = result['data'].map(result => result);
        // let staff = events.map(event => event.staff[0]);
        // console.log(staff);

        // let user = staff.map((staffs : staff) => staffs.user);
        // console.log(user);

        // let userDetails = user.map(x => x);
        // console.log(userDetails);

   
        // let combinedArray = events.concat(user);
        return events;

        
      }),//ed map
      // filter((x: StaffEvents) => )
      tap((n) => console.log(n)),
      map(user => {
        return user;
      }),
      shareReplay(),
      finalize(() => {
        //I HAVE FINALIZED - ANY INSTRUCTIONS?
        this.loadingService.loadingOff();
      })
    );
  }
  

  /**Get the Events of a Client - Client Availability*/
  _getClientEventsRaw(clientId:any, timezone: any){
    //Set parmeters
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "staff,clients");

    // const fullURL = `${this.urlRoot}blinkSession/events/client/${clientId}?${params.toString()}`;
    // console.log({ fullURL });

    //HTTP call
    return this.http.get<any[]>(this.urlRoot + "blinkSession/events/client/" + clientId, {params});
  }

  

  
   /**Get the clients login Id*/
   _getClientLoginId(clientId:any, timezone: any){
    this.loadingService.loadingOn("Loading events");
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "linkedClientLogins");
    return this.http.get<any[]>(this.urlRoot + "blinkSession/clients/" + clientId, {params})
    .pipe(
      map((response: any) => {
        return response['data'][0].linkedClientLogins[0].id;
      }),
      tap(n => console.log(n)),
      finalize(() => this.loadingService.loadingOff())
    );
    // http://localhost:8080/blinkSession/clients/{clientId}?timezone={timezone}&objects=linkedClientLogins
   }

   _getClientLoginId_LONG_THING(clientId:any, timezone: any) : Observable<any[]>{
    this.loadingService.loadingOn("Loading events");
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "clients,staff");

    // const fullURL = `${this.urlRoot}blinkSession/events/client/${clientId}?${params.toString()}`;
    // console.log({ fullURL });

    return this.http.get<any[]>(this.urlRoot + "blinkSession/events/client/" + clientId, {params})
    .pipe(
    //  tap(n => console.log(n)),
      map((res: any) => {
        return res['data']
        .map((clients: any) => {
            return clients;
        });
     
      }),
      // tap(r => console.log(r)),
      finalize(() => this.loadingService.loadingOff())
    )
   }

  


  _getSSOandRedirectDashboard(blinkSessionId: string){
    return this.http.post(this.urlRoot + "blinkSession/sso/" + blinkSessionId, blinkSessionId);
  }
  
  /**This creates user login */
  _createClientLogin(clientLogin){
    return this.http.post(this.urlRoot + "blinkSession/client-logins/", clientLogin);
  }


  /**Find all availability of the Staff with doctorsId - UI Consultation services*/
  _getStaffsAvailability(doctorId, clientsTimeZone): Observable<any[]> {
    const params = new HttpParams()
    .set("timezone", clientsTimeZone)
    .set("objects", "staff,clients")
    .set("future", "1");

    // blinkSession/events/staff/?timezone={timeZone}&{doctorId}
    // const fullURL = `${this.urlRoot}blinkSession/events/staff/${doctorId}?${params.toString()}`;
    // console.log({ fullURL });

    return this.http.get<any[]>(this.urlRoot + "blinkSession/events/staff/" + doctorId, {params})
    .pipe(
      map((result: any[]) => {
        let providers = result['data'];
       
        return providers;
      }),
      // tap((n:any) => console.log(n)),
      shareReplay(),
      finalize(() => this.loadingService.loadingOff())
    )//ed pipe
  }

  /**Get the Events of a Provider - Provider Availability*/
  _getProviderEvents(staffId:any, timezone: any){
    const params = new HttpParams()
    .set("timezone", timezone)
    .set("objects", "clients,staff")
    .set("future", "1");

      // const fullURL = `${this.urlRoot}blinkSession/events/staff/${staffId}?${params.toString()}`;
      // console.log({ fullURL });

    return this.http.get<any[]>(this.urlRoot + "blinkSession/events/staff/" + staffId, {params})
    .pipe(
      map((result: any[]) => {
        let providers = result['data'];
       
        return providers;
      }),
      // take(3)
      shareReplay(),
      finalize(() => this.loadingService.loadingOff())
    );
  }

  _deleteEvent(_eventId: any){
    return this.http.post(this.urlRoot + 'razorPayment/cancelAndRefund/' + _eventId, _eventId);
  }

  /**Add Patients availability */
  _bookAppointment(eventId: number, staffId: number): Observable<any[]>{
    return this.http.put<any[]>(this.urlRoot + "blinkSession/scheduleEvent/event/" + eventId + "/staff/" + staffId, "");
    // .pipe(
    //   finalize(() => this.loadingService.loadingOff())
    // );
    // return this.http.post(this.urlRoot + "api/appointments", postedData);
    
  }

  _GetClientNotes(clientId, clientsTimeZone): Observable<any[]>{
    this.loadingService.loadingOn();
    const params = new HttpParams()
    .set("timezone", clientsTimeZone)
    .set("objects", "clientNotes");
    // .set("future", "1"); 6734

    return this.http.get<any[]>(this.urlRoot + "blinkSession/clients/" + clientId, {params})
    .pipe(
      map((response: any) => response['data'][0]),
      tap((d: any) => console.log(d.clientNotes)),
      finalize(() => this.loadingService.loadingOff())
    )
    // http://localhost:8080/blinkSession/clients/8268?timezone=America/Antigua&objects=notes
  }

 



}
