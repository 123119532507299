import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertController } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'provider-password-modal',
  templateUrl: 'provider-password.modal.html',
})

export class ProviderPasswordModal {
  public hide:boolean = true;
  public error:string;
  public selectedLocation:any;

  constructor(
    private fb: FormBuilder, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private dialogRef: MatDialogRef<ProviderPasswordModal>, 
    private loadingService: LoadingService, 
    private apiService: ApiService, 
    private alertController : AlertController
  ) {
    this.selectedLocation = data.location;
  }

  loginForm = this.fb.group({
    password: ['', Validators.required]
  });

  authenticateBahmniUser(value) {
    let session = JSON.parse(localStorage.getItem('SESSION'));
    let token = JSON.parse(localStorage.getItem('TOKEN'));
    const data = {
      locationUuid : this.selectedLocation && this.selectedLocation.bahmniUuid ? this.selectedLocation.bahmniUuid : null,
      password: this.loginForm.value.password,
      access_token: token.access_token
    }
    this.dialogRef.close();
    this.loadingService.loadingOn();
    this.apiService.authenticateBahmniUser(data).subscribe((response:any) => {
      this.loadingService.loadingOff();
      if(response.sessionId) {
        localStorage.setItem('SESSION', JSON.stringify(response));
        this.successMessageAlert();
      } else {
        this.presentRegFailAlert()
      }
      
    }, (error) => {
      this.loadingService.loadingOff();
      this.presentRegFailAlert()
    })
  }

  // Add Provider Location Sucess Alert
  async successMessageAlert(){
    const alert = await this.alertController.create({
      header: 'Location Update Status',
      subHeader: 'Success',
      message: 'Location Updated successfully',
      buttons: [{
        text: 'Ok',
        handler: () => {}
      }],
      backdropDismiss: false
    });
    await alert.present();
  }

  // Add Provider Location Fail Status Alert
  async presentRegFailAlert(){
    const alert = await this.alertController.create({
      header: 'Location Update Status',
      subHeader: 'Failed',
      message: 'Location Update Failed. Please try again.',
      buttons: [{
        text: 'Ok',
        handler: () => {}
      }],
      backdropDismiss: false
    });
    await alert.present();
  }
}
