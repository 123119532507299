import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { map, tap, filter } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class UsernameValidator {

  debouncer: any;

  constructor(public apiService: ApiService){

  }

  /**Checking email for shouldBeUnique while registering Clients / Adult / User*/
  checkUsername(control: FormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
  //  console.log("control", control);
  //  console.log("control Len", control.value.length);
  //  console.log("control TOuched", control.touched);
    //Clears Debouncer
    clearTimeout(this.debouncer);
    
    //Return Promise | null
    return new Promise(resolve => {
        if(control.value.length <= 0 ) {
          resolve(null);
        }
        //Adds Debouncer - to space out http calls by one second
        this.debouncer = setTimeout(() => {
        this.apiService.validateClientsEmail(control.value)
        .subscribe((res) => {
          if(control.value.length <= 0 ) {
            // console.log("Before Resolve");
            resolve(null);
            // console.log("After Resolve");
          }

          if(res === false){
            resolve(null);
          } else{
              resolve({'shouldBeUnique': true});
          }
        }, (err) => {
            console.log(err);
            if(control.value.length <= 0 ) {
              resolve(null);
            }
            resolve(null);
          // resolve({'shouldBeUnique': true});
        });

      }, 1000);      

    });
  }

 
  /**Checking email for shouldBeUnique while registering Doctors / Providers / User*/
  checkDoctorsEmail(control: FormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    
    clearTimeout(this.debouncer);

    return new Promise(resolve => {
    
        this.debouncer = setTimeout(() => {
        this.apiService.validateDoctorsEmail(control.value)
        .subscribe((res) => {
            console.log(res);
          if(res === false){
            resolve(null);
          } else{
              resolve({'shouldBeUnique': true});
          }
        }, (err) => {
            console.log(err);
          resolve({'shouldBeUnique': true});
        });

      }, 1000);      

    });
  }

  /**Checking email for shouldBeUnique while registering Doctors / Adult / Any User*/
  checkAnyEmail(control: FormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    
    clearTimeout(this.debouncer);

    return new Promise(resolve => {
    
        this.debouncer = setTimeout(() => {
        this.apiService._validateAnyEmail(control.value)
        .subscribe((res) => {
            console.log(res);
          if(res === true){
            resolve(null);
          } else{
              resolve({'shouldBeUnique': true});
          }
        }, (err) => {
            console.log(err);
          resolve({'shouldBeUnique': true});
        });

      }, 1000);      

    });
  }


  /**Checking email for shouldBeUnique while registering Clients / Adult / User*/ REVERSE
  allowValidUsername(control: FormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    // console.log("control", control);
    // console.log("control Len", control.value.length);
    // console.log("control Touched", control.touched);
     //Clears Debouncer
     clearTimeout(this.debouncer);
     
     //Return Promise | null
     return new Promise(resolve => {
         if(control.value.length <= 0 ) {
           resolve(null);
         }
         //Adds Debouncer - to space out http calls by one second
         this.debouncer = setTimeout(() => {
         this.apiService._validateAnyEmail(control.value)
         .subscribe((res) => {
          //  console.log("res: ", res);
           if(control.value.length <= 0 ) {
             
             resolve(null);
     
           }
 
           if(res === true){
             resolve(null);
           } else{
               resolve({'shouldBeUnique': true});
           }
         }, (err) => {
             console.log(err);
             if(control.value.length <= 0 ) {
               resolve(null);
             }
             resolve(null);
           // resolve({'shouldBeUnique': true});
         });
 
       }, 1000);      
 
     });
   }


}