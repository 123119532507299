import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CreateAccountValidator } from 'src/app/home/create-account/create-account.validators';
import { ModalController, AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { BlinkSessionService } from './../../services/blink-session.service';
import { LoadingService } from 'src/app/services/loading.service';
// import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';


import { filter, tap, map, shareReplay, finalize } from 'rxjs/operators'; 
import { Doctor } from 'src/app/interfaces/doctors';
import { HttpErrorResponse } from '@angular/common/http';
import { RazorPayService } from 'src/app/services/razor-pay.service';
import { environment } from 'src/environments/environment';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { AwsServiceService } from 'src/app/services/aws-service.service';
import { now } from 'moment';
import { start } from 'repl';
import { getLocaleDateTimeFormat } from '@angular/common';

declare var paypal;

@Component({
  selector: 'app-schedule-appointment',
  templateUrl: './schedule-appointment.component.html',
  styleUrls: ['./schedule-appointment.component.scss'],
})
export class ScheduleAppointmentComponent implements OnInit {

  //DATE PICER MIN AND MAX VALUES
  minDate = new Date(2000, 0, 1);
  maxDate = new Date(2020, 10, 1);
  
  @Input() doctorsId:any; //Passed in from modal
  @Input() doctorsTimeZone:any; //Passed in from modal
  @Input() doctorsConsultationHrFee: any;//Passed in from modal
  @Input() doctorsBlinkSessionId: any;
  @Input() doctorCategory: any;
  @Input() aboutYourself: any;
  @Input() doctorsFullname: any;
  @Input() doctorsSpecialization: any;
  @Input() doctorsSuperSpeciality: any;
  @Input() doctorsLocation: any;
  @Input() doctorsPracticingFrom: any; 
  @Input() doctorsPresentlyPracticingAt: any; 
  @Input() doctorsYearsOfExperience: any;

  
  //Coupon
  addCouponFrm: FormGroup;
  couponValid: any = false;
  finalPriceAfterCoupon: any;

  schAppForm: FormGroup;
  imageUrlFromS3: any;
  docAvailability: any; //SET THIS TO BE OF TYPE INTERFACE (DcotorAvailability)
  timeslots: any;
  filterVariable: any;
  appointmentTakenDate: any;
  postDataResponse;
  selectedDate: any;
  selectedStartTime; //Date user selected, gotten from filtering by form.appointment.id
  selectedEndTime; //Date user selected, gotten from filtering by form.appointment.id
  userDetails: any;
  test:any;
  selectedDoctorsDetails: Doctor;

  //RAZOR PAY
  currency: string = 'INR'; // your 3 letter currency code
  razor_key: string =  environment.razorpayClientId; // your Key Id from Razorpay dashboard
  paymentAmount: any = "₹50.00";
  razorpay_payment_id: any = null;
  razorpay_order_id: any;
  razorpay_signature: any;
  successPayment: boolean = false;

  // PAYPAL
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  showSuccess;
  paypalDetails;

  product = {
    price: 777.77,
    description: 'used couch, decent condition',
    img: 'assets/couch.jpg'
  };

  paidFor = false;

  constructor(
    private winRef: WindowRefService,
    private fb: FormBuilder,
    //private viewCtrl: ViewController
    private modalController: ModalController,
    private apiService: ApiService,
    // private loadingController: LoadingController,
    public alertController: AlertController,
    // private authService: AuthService,
    private loadingService: LoadingService,
    private blinkSessionService: BlinkSessionService,
    public router: Router,
    // private windows: Window,
    private razorPayService: RazorPayService,
    private awsService: AwsServiceService
    ) { }

  
  
  ngOnInit() {
    // this.authService._addLoggedInUserToStorage();
    this.getUserDetailsFromStorage();
    // this.apiService._getLoggedInDoctorDetailsIntoStorage(); USE THIS TO GET DOCTOR
    
    if(this.doctorsBlinkSessionId) //This provides data for the date dropdown
    {
      this.loadingService.loadingOn();
        
        if(this.doctorsId){
          this.getSelectedDoctorDetails(this.doctorsId);
        }
      // this.filterVariable = this.blinkSessionService._getStaffsAvailability(this.doctorsBlinkSessionId, this.doctorsTimeZone)
      // this.filterVariable = this.blinkSessionService._getStaffsAvailability(this.doctorsBlinkSessionId, this.userDetails.timezone)

      this.filterVariable = this.blinkSessionService._getProviderEvents(this.doctorsBlinkSessionId, this.userDetails.timezone)
      .pipe(
        tap(res => console.log(res)),
        map((i: any) => {//This map searches tru data, filtering out the available slots, and showing them
          console.log('i: ', i);
          
          return i.filter(subArray => {
            // return subArray['title'].indexOf('available slot') !== -1;
            return subArray['clients'].length === 0;
          })
         
        }),
        tap(res => console.log(res)),
        // shareReplay(),
        tap(n => {
          // console.log(n);
          if(n.length===0){
            this.apiService.presentAlertApi('SCHEDULE STATUS!', 'This Provider has no available slots', 'ok');
          };
        })
        //map(dayOfWeek => dayOfWeek === dayOfWeek.start)
      )
      .subscribe(res => 
        { 
          //this.presentLoading();   //Present Loader here - while data is being fetched
          this.docAvailability = res;
          // this.loadingService.loadingOff();
          //Convert this.test to y-MM-dd, to filter between dates
          //this.test = 
          this.docAvailability
          .map((res: any) => {
            res.startDate = this.apiService._convertDateFormatTo(res.start, "y-MM-dd")
          });
          this.test = this.docAvailability.filter((d: any) => {
            return new Date(d.start) >= new Date();
          });
          this.docAvailability = this.test;
          /* Removing redundant rows */
          var obj = {};
          for ( var i = 0, len = this.test.length; i < len; i++ )
            obj[this.test[i]['startDate']] = this.test[i];
          obj = Object.keys(obj).sort().reduce(function (result, key) {
            result[key] = obj[key];
            return result;
          }, {});
          var newData = new Array();
          for ( var key in obj )
            newData.push(obj[key]);
          this.test = newData;           
          //.filter((n:any) => n.title === '** available slot **')
          // console.log("Docs test: " + JSON.stringify(this.test));
        },error => console.error(error));
      }
      else{
        this.docAvailability = null;
        this.timeslots = null;
        this.test = null;
      }
    
      
      this.addCouponFrm = this.fb.group({
        couponInp: ['', Validators.minLength(5)],
      });

    this.schAppForm = this.fb.group({
      schDate: ['', Validators.required],
      schSlot: ['', Validators.required],
      appointmentReason: ['', Validators.required]
    });

  }

  ionViewDidEnter(){
    
    //CONVERT AMOUNT FROM CURRENCY TO NUMBER WITH 00
    let amount: any = this.apiService._currencyConverterRemoverPaypal(this.finalPriceAfterCoupon ? this.finalPriceAfterCoupon : this.doctorsConsultationHrFee, "$");
    // console.log(amount);

    // console.log(this.paypalElement.nativeElement, this.doctorCategory);

    if(this.doctorCategory === 'international' ){
      paypal
      .Buttons({
        style: {
          color: 'blue',
          layout: 'horizontal',
          shape: 'rect',
          label: 'paypal'
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: this.product.description,
                amount: {
                  currency_code: 'USD',
                  value: amount
                }
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture().then(details => {
            // alert('Transaction Complete.')
            console.log('Transaction onApprove.', details)
          });
            this.paidFor = true;
            console.log(order);
            console.log('data', data);

            this.paypalDetails = data;

            //Save into DB
            const paypalDetailsObj = {
              paymentId: data.id,
              amount: amount,
              currency: 'USD',
              description: 'Paypal payment for scheduling a session',
              isPaymentCaptured: true,
              payeeId: data.payerID,
              orderID: data.orderID,
              billingToken: data.billingToken,
              facilitatorAccessToken: data.facilitatorAccessToken,
              blinkSessionEventId: this.schSlot,
              doctorsId: this.doctorsId,
              docBlinkSesnId: this.doctorsBlinkSessionId
            }

            console.log(paypalDetailsObj);

            this.razorPayService._insertPaypalPaymentSuccessinfo(paypalDetailsObj)
              .subscribe(res => {
                console.log(res);
              },(error: HttpErrorResponse) => console.log("could not save payment info", error));

            // SCHEDULE APPOINTMENT HERE AFTER PAYMENT
            this.scheduleAppointment(this.schSlot, this.doctorsId);

        },
        // onApprove: (data, actions) => {
        //   console.log('onApprove - transaction was approved, but not authorized', data, actions);
        //   actions.order.get().then(details => {
        //     console.log('onApprove - you can get full order details inside onApprove: ', details);
        //   });
        // },
        onClientAuthorization: (data) => {
          console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
          this.showSuccess = true;

          // this.paypalDetails = data;

          // //Save into DB
          // const paypalDetailsObj = {
          //   paymentId: data.id,
          //   amount: amount,
          //   currency: 'USD',
          //   description: 'Paypal payment for scheduling a session',
          //   isPaymentCaptured: true,
          //   payeeId: data.payerID,
          //   orderID: data.orderID,
          //   billingToken: data.billingToken,
          //   facilitatorAccessToken: data.facilitatorAccessToken
          // }

          // console.log(paypalDetailsObj);

          // this.razorPayService._insertPaymentSuccessinfo(paypalDetailsObj)
          //   .subscribe(res => {
          //     console.log(res);
          //   },(error: HttpErrorResponse) => console.log("could not save payment info"));

          // // SCHEDULE APPOINTMENT HERE AFTER PAYMENT
          // this.scheduleAppointment(this.schSlot, this.doctorsId);

        },
        onError: err => {
          console.log(err);
        }
      })
      .render(this.paypalElement.nativeElement);
    }
  }

  

  scheduleAppointment(schSlot: any, doctorsId: any){
    this.loadingService.loadingOn();
    this.blinkSessionService._bookAppointment(this.schAppForm.value.schSlot, this.doctorsBlinkSessionId)
    .pipe(
      finalize(() => this.loadingService.loadingOff())
    )
    .subscribe((Response: any)=> {
      this.postDataResponse = Response;
     
      //console.log(this.postDataResponse);
      // if (this.postDataResponse.status_code === 200)
      if(Response)
      {
        // this.razorPayService._insertPaymentSuccessinfo(this.razorpay_payment_id, options.amount, options.currency, options.description, payment_capture(true));
        this.apiService.presentAlertApiHandler('Appointment Status', 'Appointment Successfully added. Go to dashboard.', 'OK', '/dashboard'); //alert("Appointment Successfully added");
        this.schAppForm.reset(); 
        this.cancelClicked();
      }
      
    },(error) => {
      console.error("An error Occured: ", JSON.stringify(error));
      this.apiService.presentAlertApi("ALERT!!!", 'Form was not submitted, an error occured, please contact support', 'OK')
 
    });
  }

  payWithRazorpay(schSlotP: any, doctorsIdP: any) {
    this.finalPriceAfterCoupon = this.finalPriceAfterCoupon ? this.finalPriceAfterCoupon : this.doctorsConsultationHrFee;
    const options: any = {
      
      description: 'Telehealth consultation payment - Razorpay',
      image: './assets/Corvus-Logo-blue-favicon.png',
      currency: 'INR', // 3 letter currency code - this.currency
      key: this.razor_key, // Key Id from Razorpay dashboard rzp_test_jmbFpc6gwXg2OH
      amount: this.apiService._currencyConverterRemover(this.finalPriceAfterCoupon ? this.finalPriceAfterCoupon : this.doctorsConsultationHrFee, "₹"), // Payment amount in smallest denomiation e.g. cents for USD
      name: 'Vedus Health',

      //order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      prefill: {
        email: this.userDetails.email,
        contact: this.userDetails.phone,
        name: this.userDetails.firstName + ' ' + this.userDetails.lastName
      },
      notes: {
        address: 'India'
      },
      theme: {
        color: '#3880ff'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      // console.log("Response 245: " + JSON.stringify(response));
      // console.log(options);

      if(response){
        options.response = response;
        this.razorpay_payment_id = response.razorpay_payment_id;

        //Save into DB
        const razorDetails = {
          paymentId: this.razorpay_payment_id, 
          amount: options.amount, 
          currency: options.currency, 
          description: options.description, 
          isPaymentCaptured: false,
          blinkSessionEventId: schSlotP,
          doctorsId: this.doctorsId,
          doctorsBlinkSessionId: doctorsIdP //this.doctorsBlinkSessionId
        }

        console.log(razorDetails);

        //Save to razorPay DB entity
        this.razorPayService._insertPaymentSuccessinfo(razorDetails)
        .subscribe(res => {
          console.log(res);

          // SCHEDULE APPOINTMENT HERE AFTER PAYMENT
          this.scheduleAppointment(schSlotP, doctorsIdP);

        },(error: HttpErrorResponse) => console.log("could not save payment info"));
          
        // SCHEDULE APPOINTMENT HERE AFTER PAYMENT
        // this.scheduleAppointment(schSlotP, doctorsIdP);
      }else{
        console.log("Error: There was no response");
      }
      
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
      this.apiService.presentAlertApi('PAYMENT STATUS', 'Payment was cancelled, please complete payment to schedule appointment.', 'OK'); //alert("Payment Cancelled");
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  
  private payWithPaypal(schSlotP: any, doctorsIdP: any): void{
      
      //CONVERT AMOUNT FROM CURRENCY TO NUMBER WITH 00
      let amount: any = this.apiService._currencyConverterRemoverPaypal(this.doctorsConsultationHrFee, "$");
      console.log(amount);

    //   this.payPalConfig = {
    //   currency: 'USD',
    //   clientId: environment.paypalClientId,
    //   createOrderOnClient: (data) => <ICreateOrderRequest>{
    //     intent: 'CAPTURE',
    //     purchase_units: [
    //       {
    //         amount: {
    //           currency_code: 'USD',
    //           value: amount,
    //           breakdown: {
    //             item_total: {
    //               currency_code: 'USD',
    //               value: amount
    //             }
    //           }
    //         },
    //         items: [
    //           {
    //             name: 'Enterprise Subscription',
    //             quantity: '1',
    //             category: 'DIGITAL_GOODS',
    //             unit_amount: {
    //               currency_code: 'USD',
    //               value: amount,
    //             },
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   advanced: {
    //     commit: 'true'
    //   },
    //   style: {
    //     label: 'checkout',
    //     layout: 'horizontal',
    //     color: 'blue',
    //     shape: 'rect',
    //     size: 'responsive'
    //   },
    //   onApprove: (data, actions) => {
    //     console.log('onApprove - transaction was approved, but not authorized', data, actions);
    //     actions.order.get().then(details => {
    //       console.log('onApprove - you can get full order details inside onApprove: ', details);
    //     });
    //   },
    //   onClientAuthorization: (data) => {
    //     console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
    //     this.showSuccess = true;
        
    //     this.paypalDetails = data;
      
    //     //Save into DB
    //     const paypalDetails = {
    //       paymentId: data.id, 
    //       amount: amount, 
    //       currency: 'USD', 
    //       description: 'Paypal payment for scheduling a session', 
    //       isPaymentCaptured: true,
    //       payer_id: data.payer.payer_id
    //     }

    //     console.log(paypalDetails);

    //     this.razorPayService._insertPaymentSuccessinfo(paypalDetails)
    //     .subscribe(res => {
    //       console.log(res);
    //     },(error: HttpErrorResponse) => console.log("could not save payment info"));
          
    //     // SCHEDULE APPOINTMENT HERE AFTER PAYMENT
    //     this.scheduleAppointment(schSlotP, doctorsIdP);

    //   },
    //   onCancel: (data, actions) => {
    //     console.log('OnCancel', data, actions);
    //   },
    //   onError: err => {
    //     console.log('OnError', err);
    //   },
    //   onClick: (data, actions) => {
    //     console.log('onClick', data, actions);
    //   },
    // };
   
    paypal
    .Buttons({
      style: {
        color: 'blue',
        layout: 'horizontal',
        shape: 'rect',
        label: 'paypal'
      },
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: this.product.description,
              amount: {
                currency_code: 'USD',
                value: this.product.price
              }
            }
          ]
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture().then(details => {
          alert('Transaction Complete.')
        });
        this.paidFor = true;
        console.log(order);
        console.log('data', data);

      },
      onError: err => {
        console.log(err);
      }
    })
    .render(this.paypalElement.nativeElement);
  }

  formSubmit(){
    console.log(this.schAppForm);

    //this.appointmentTakenDate === val;
    this.selectedDate = this.timeslots.filter((fltvar) => 
    {
      return fltvar.id === this.schAppForm.value.schSlot
    });
    // console.log("sel: ", JSON.stringify(this.selectedDate[0]));

    this.selectedStartTime = this.selectedDate[0].start;
    this.selectedEndTime = this.selectedDate[0].end;
    //Date user selected, gotten from filtering by form.appointment.id

    console.log("this.selectedDate: " + JSON.stringify(this.selectedDate[0].start));

    let min = this.apiService.getMinutesBetweenDates(this.selectedStartTime, this.selectedEndTime);
    //console.log("MIN: " + min);
    let dateToString: string = this.apiService._convertDateFormatTo(this.selectedStartTime, "y-MM-d HH:mm")
    
    console.log("SUN: " + dateToString);
    console.log("ClientId: " + this.userDetails.blinkSessionClientId);
    console.log("linked_staff_ids: " + " " + this.doctorsBlinkSessionId);
    console.log("event_slot: " + " " + this.schAppForm.value.schSlot);


    // const postedData = {"doctors": {"id": this.doctorsId}, "doctorsAvailability": {id: this.schAppForm.value.schSlot}, "probableStartTime": this.selectedStartTime, "actualEndTime": this.selectedEndTime, "appointmentStatusId": "pending", "appointmentTakenDate": this.schAppForm.value.schDate, "appointmentReason": this.schAppForm.value.appointmentReason};
    const postedData = {
      "linked_staff_ids": this.doctorsBlinkSessionId, 
      "timezone": this.userDetails.timezone, 
      "start": dateToString, 
      //"start": "2020-06-17 18:50",
      "minutes": 30,
      // "linked_client_ids": "6734",
      "linked_client_ids": this.userDetails.blinkSessionClientId, 
      "details": this.schAppForm.value.appointmentReason
    };

    //Get Doctor Info with doctorId
    // this.apiService._getDoctorById(this.doctorsId);

    if(this.doctorCategory === "national"){
      console.log(this.doctorCategory);
      this.payWithRazorpay(this.schAppForm.value.schSlot, this.doctorsBlinkSessionId);
    }else if(this.doctorCategory === "international"){
      console.log(this.doctorCategory);
      this.payWithPaypal(this.schAppForm.value.schSlot, this.doctorsBlinkSessionId);
    }else{
      console.log('There is no doctors category here');
    }
    
  }

  cancelClicked(){
    //Dismiss codal controller
    this.modalController.dismiss();
  }

  public onChangeScheduleDate(val){
    this.appointmentTakenDate === val;
    this.timeslots = this.docAvailability.filter((fltvar) => 
    {
      return fltvar.startDate === val
    })
    
    // console.log("Val: " + val)
    // console.log(this.timeslots)
  }

  
  /**
   * Reactive Form Get Form Feilds
   */
  get schDate(){
    return this.schAppForm.get("schDate");
  }

  get schSlot(){
    return this.schAppForm.get("schSlot");
  }

  get appointmentReason(){
    return this.schAppForm.get("appointmentReason");
  }


  get couponInp(){
    return this.addCouponFrm.get("couponInp");
  }

  onAddCoupon(){
    console.log(this.addCouponFrm.value.couponInp );
    const couponCode = this.addCouponFrm.value.couponInp;

    this.apiService.validateCouponCode(couponCode, this.doctorsId)
    .subscribe((data: any) => {
      if(data.status !== "invalid" && typeof data === "number"){
          
          if(this.doctorCategory === "national"){
            this.finalPriceAfterCoupon = `₹${data}.00`;
            this.couponValid = true; //Only true if form is valid
           
            this.apiService.presentToast("Coupon successfully applied!", 2000);
          }else if(this.doctorCategory === "international"){
            this.finalPriceAfterCoupon  = `$${data}.00`;
            this.couponValid = true; //Only true if form is valid
            this.apiService.presentToast("Coupon successfully applied!", 2000);
          }
        
      }else{
        this.apiService.presentAlertApi('INVALID COUPON CODE!', data.message, 'ok');
      }

     
    }, (err: HttpErrorResponse) => {
      console.log("err:", err)
      this.apiService.presentToast("Error with Code, please try another later!", 1000);
    })
    
  }


  getUserDetailsFromStorage(){
    let user:any;
    user = JSON.parse(localStorage.getItem('currentUser'));
    if(user)
    {
      this.userDetails = user;
      // console.log("User Info: " + JSON.stringify(this.userDetails));
    }
  }

  getSelectedDoctorDetails(doctorsId: number){
    this.apiService._getDoctorById(doctorsId)
    .subscribe((response: Doctor) => {
      // console.log('selectedDoctorsDetails', response);
      this.selectedDoctorsDetails = response;

      if(response.profilePicDocumentKey){
        this.imageUrlFromS3 = this.awsService.getDocumentWithKey(response.profilePicDocumentKey);  
        // console.log(this.imageUrlFromS3);                                                                                                                                                                                                                                
      }

      // if(response.doctorCategory === "international"){
      //   console.log(response.doctorCategory);
      //   // this.payWithRazorpay(this.schAppForm.value.schSlot, this.doctorsBlinkSessionId);
      // }else if(response.doctorCategory === "national"){
      //   console.log(response.doctorCategory);
      //   // this.payWithPaypal();
      // }
    },(error: HttpErrorResponse) => {
      console.log(error);
    });
  } 


}
