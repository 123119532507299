import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
  } from '@angular/core';
  import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
  import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
  
  @Component({
    selector: 'chat-gpt-modal',
    templateUrl: './chat-gpt.component.html',
  })
  export class ChatGptModal implements OnInit {
    url: string = 'https://jacobs0925.github.io/Vedus#general%20practitioner';
    urlMap: SafeResourceUrl | undefined;
    constructor(
      private _mdr: MatDialogRef<ChatGptModal>,
      @Inject(MAT_DIALOG_DATA) data: any,
      public sanitizer: DomSanitizer,
      private cdr: ChangeDetectorRef
    ) {
      
    }
    CloseDialog() {
      this._mdr.close({
        data: {
          modelClose: true,
        },
      });
    }


    ngOnInit() {
      this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
  }
  