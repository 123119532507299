import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'emr-location-modal',
	templateUrl: 'emr-location.modal.html',
})
	
export class EmrLocationModal {
	public filterdLocations: ReplaySubject<any> = new ReplaySubject<any>(1);
	protected _onDestroy = new Subject<void>();
	public error :any;

	constructor(
		private fb: FormBuilder, 
		@Inject(MAT_DIALOG_DATA) public data: any, 
		public loadingService: LoadingService, 
		private dialogRef: MatDialogRef<EmrLocationModal>, 
	) {
		this.filterdLocations.next(this.data.locations.slice());
		this.selectLocationForm.get('locationFilterCtrl').valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
				this.filterLocations();
		});
	}

	selectLocationForm = this.fb.group({  
		locations : ['', [Validators.required]],
		locationFilterCtrl: ['', []]
	});

	protected filterLocations() {
		if (!this.data) {
			return;
		}
		// get the search keyword
		let search = this.selectLocationForm.get('locationFilterCtrl').value;
		if (!search) {
			this.filterdLocations.next(this.data.locations.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		this.data.locations.filter(location => location.name.toLowerCase().indexOf(search) > -1)
		// filter the locations
		this.filterdLocations.next(
			this.data.locations.filter(location => location.name.toLowerCase().indexOf(search) > -1)
		);
	}

	selectLocation(value) {
		this.dialogRef.close(this.selectLocationForm.get('locations').value);
	}

	protected ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}
}
	
	