import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AlertController } from '@ionic/angular';
import { LoadingService } from 'src/app/services/loading.service';
import { ReplaySubject, Subject } from 'rxjs';
import { tap, take, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { RazorPayService } from 'src/app/services/razor-pay.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'book-appointment',
	templateUrl: 'book-appointment.modal.html',
	styleUrls: ['../../home/book-appointment/book-appointment.page.scss'],
})

export class BookAppointmentModal {
	public minDate = new Date();
	public error:string;
	public isWalkIn:boolean = false;
	public isVideoConsultant:boolean = false;
	public searchingAppointment:boolean = false;
	public services:any = [];
	public filterdServices:any = [];
	public delayTimer;
	protected _onDestroy = new Subject<void>();
	public noSlotsFound:string = null;
	public appointmentSlots:any = [];
	public loading:boolean = false;
	public selectedSlot:any;
	private userDetails:any;
	private razor_key: string =  environment.razorpayClientId; // your Key Id from Razorpay dashboard
	private razorpay_payment_id: any = null;
	public doctor:any;
	public type:string;

	scheduleForm = this.fb.group({
		scheduleDate: ['', [Validators.required]],
		services: ['', [Validators.required]],
		serviceFilterCtrl: ['', []]
	});

	constructor(
		private fb: FormBuilder, 
		@Inject(MAT_DIALOG_DATA) public data: any, 
		private apiService:ApiService, 
		public loadingService: LoadingService, 
		public dialogRef: MatDialogRef<BookAppointmentModal>, 
		private alertController : AlertController,
		private ar: ActivatedRoute,
		private winRef: WindowRefService,
		private razorPayService: RazorPayService
	) 
	
	{
		this.scheduleForm.get('serviceFilterCtrl').valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.searchAppointmentServices(this.scheduleForm.get('serviceFilterCtrl').value);
	    });

		this.getDoctorById();
		
		this.ar.queryParams.subscribe(
			 (r:any)=> {
			  console.log(r)
			  this.type=r.type;
			 }
			)
	}

	public getDoctorById() {
		this.apiService._getDoctorById(this.data.doctorId).subscribe((response) => {
			this.doctor = response;
			this.searchAppointmentServices(this.scheduleForm.get('serviceFilterCtrl').value);
		}, (error) => {
			console.log('error', error);
		})
	}

	// Search Appointment Services 
	protected searchAppointmentServices(text) {
		// if(!text)
		// 	return;

		this.searchingAppointment = true;
		clearTimeout(this.delayTimer);
		this.delayTimer = setTimeout(() => {
			//const locationText = localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).location ? JSON.parse(localStorage.getItem('currentUser')).location : '';
			const locationText = localStorage.getItem('SESSION') && JSON.parse(localStorage.getItem('SESSION')).locationName ? JSON.parse(localStorage.getItem('SESSION')).locationName : '';
			this.apiService.searchPorvider(this.doctor.email).subscribe((resp:any)=>{
				this.apiService.searchAppointmentService(text, locationText, resp.result[0].providerUuid).subscribe((res:any)=>{
					this.filterdServices = res.pageOfResults;
					this.getAppoitmentSlot(this.scheduleForm.value);
					this.searchingAppointment = false;
				}, error=>{
					this.filterdServices = [];
					this.searchingAppointment = false;
				})
			})
	    }, 1000); 
	}

	slotSelect(slot) {
		this.selectedSlot = slot;
	}

	// Get Appointment Slots
	getAppoitmentSlot(value) {
		this.noSlotsFound = null;
		this.appointmentSlots = [];
		if(value.scheduleDate && value.services.uuid) {
			this.loading = true;
			const scheduleDate = moment(value.scheduleDate).format('YYYY-MM-DD');
			this.apiService.getAppointmentServiceSlot(value.services.uuid, scheduleDate).subscribe((response:any) => {
				if(!response.length)
					this.noSlotsFound = "No Slots Found, Please Select different date.";
				
				this.appointmentSlots = response;
				this.appointmentSlots.map(slot=>{
					slot.appointmentSlotStartTime = moment(slot.appointmentSlotStartTime, 'HH:mm:ss').format('hh:mm a');
					slot.appointmentSlotEndTime = moment(slot.appointmentSlotEndTime, 'HH:mm:ss').format('hh:mm a');
				})
				this.loading = false;				

			}, (error) => {
				this.loading = false;
				if(error)
					this.noSlotsFound = "Please try again.";
			});
		}
	}

	payWithRazorPay(): void {
		this.apiService.getClinicByLocationUuid(this.scheduleForm.value && this.scheduleForm.value.services && this.scheduleForm.value.services.location.uuid ? this.scheduleForm.value.services.location.uuid : '').subscribe((clinicLocation) => {

		this.dialogRef.close(true);
	 	this.userDetails = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
	 
	 	const options: any = {
	        description: 'Telehealth consultation payment - Razorpay',
	        image: 'assets/icon/favicon.png',
	        currency: 'INR',
	        key: this.razor_key, // Key Id from Razorpay dashboard rzp_test_jmbFpc6gwXg2OH
			// key: clinicLocation.razorPayKey,
			name: 'Vedus Health',
			amount : this.apiService._currencyConverterRemover(this.doctor && this.doctor.consultationHrFee ? this.doctor.consultationHrFee : '₹1', "₹"),
	      	modal: {
	        	escape: false,
	      	},
	      	prefill: {
				email: this.userDetails.email,
	        	contact: this.userDetails.phone,
	        	name: this.userDetails.firstName + ' ' + this.userDetails.lastName
	      	},
	      	notes: {
	        	address: 'India'
	      	},
	      	theme: {
	        	color: '#3880ff'
	      	}
	    };
	   
		options.handler = ((response, error) => {
	      	options.response = response;

	      	if(response){
	        	options.response = response;
	        	this.razorpay_payment_id = response.razorpay_payment_id;

		        //Save into DB
		        const razorDetails = {
			        paymentId: this.razorpay_payment_id, 
			        amount: options.amount, 
			        currency: options.currency, 
			        description: options.description, 
			        isPaymentCaptured: false,
			 		locationUuid: this.scheduleForm.value && this.scheduleForm.value.services && this.scheduleForm.value.services.location.uuid ? this.scheduleForm.value.services.location.uuid : ''
		        }

		        //Save to razorPay DB entity
				this.razorPayService._insertPaymentSuccessinfo(razorDetails).subscribe(res => {
				// 	console.log('razor pay service', res);

		          	// SCHEDULE APPOINTMENT HERE AFTER PAYMENT
		          	this.confirmAppointment(razorDetails);

				},(error) => {
					console.log("could not save payment info")
				});
  	        } else {
	        	console.log("Error: There was no response");
			}
	    });

	    options.modal.ondismiss = (() => {
	      	this.apiService.presentAlertApi('PAYMENT STATUS', 'Payment was cancelled, please complete payment to schedule appointment.', 'OK');
	    });
	 
	    const rzp = new this.winRef.nativeWindow.Razorpay(options);
	    rzp.open();
	});
    }

	// Confirm Appointment Book
	confirmAppointment(data) {
		this.dialogRef.close(true);
		this.loadingService.loadingOn();

		let startDateTime = this.scheduleForm.value && this.scheduleForm.value.scheduleDate && this.selectedSlot ? this.scheduleForm.value.scheduleDate : new Date(+new Date() + 86400000);
		let endDateTime = this.scheduleForm.value && this.scheduleForm.value.scheduleDate && this.selectedSlot ? this.scheduleForm.value.scheduleDate : new Date(+new Date() + 86400000);
		let appointementData; 
		if(this.type=='child'){
		  appointementData = {
			patientUuid : sessionStorage.getItem('child') ? JSON.parse(sessionStorage.getItem('child')).bahmniUuid : null,
			serviceUuid : this.scheduleForm.value && this.scheduleForm.value.services.uuid ? this.scheduleForm.value.services.uuid : '',
		    startDateTime : moment(startDateTime).format('YYYY-MM-DD')+ 'T' + moment(this.selectedSlot.appointmentSlotStartTime, 'hh:mm a').format('HH:mm:ss'),
		    endDateTime : moment(endDateTime).format('YYYY-MM-DD')+ 'T' + moment(this.selectedSlot.appointmentSlotEndTime, 'hh:mm a').format('HH:mm:ss'),
		    appointmentKind : this.isVideoConsultant ? "Scheduled" : "WalkIn",
			teleconsultation : this.isVideoConsultant ? true : false, 
		    comments : '',
		}
	    }
		else{
			appointementData = {
				patientUuid : localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).bahmniUuid : '',
				serviceUuid : this.scheduleForm.value && this.scheduleForm.value.services.uuid ? this.scheduleForm.value.services.uuid : '',
			    startDateTime : moment(startDateTime).format('YYYY-MM-DD')+ 'T' + moment(this.selectedSlot.appointmentSlotStartTime, 'hh:mm a').format('HH:mm:ss'),
				endDateTime : moment(endDateTime).format('YYYY-MM-DD')+ 'T' + moment(this.selectedSlot.appointmentSlotEndTime, 'hh:mm a').format('HH:mm:ss'),
			    appointmentKind : this.isVideoConsultant ? "Scheduled" : "WalkIn",
			    //teleconsultation : this.razorpay_payment_id ? true : false,
				teleconsultation : this.isVideoConsultant ? true : false, 
			    comments : '',
			}
		}

		let body = {
			appointment : appointementData, 
			paymentType: this.razorpay_payment_id ? "online" : "walkin", 
			paymentId : this.razorpay_payment_id,
		    //providerUuid : this.scheduleForm.value && this.scheduleForm.value.services && this.scheduleForm.value.services.provider.uuid ? this.scheduleForm.value.services.provider.uuid : ''
		    providerName : this.scheduleForm.value && this.scheduleForm.value.services && this.scheduleForm.value.services.provider.name ? this.scheduleForm.value.services.provider.name : ''
		}

		if(appointementData.startDateTime && appointementData.endDateTime) {


			this.apiService.appointmentBooking(body).subscribe((response) => {
				this.loadingService.loadingOff();
				 this.successAlertAppointment();
			 }, (error) => {
				 this.loadingService.loadingOff();
			   this.error="Something went wrong";
			   this.presentRegFailAlertAppointment();
			  })
		}
	}

	// Book Appointment Success Alert
	async successAlertAppointment(){
		const alert = await this.alertController.create({
			header: 'Book Appointment Status',
			subHeader: 'Success',
			message: 'Your appointment has been booked successfully.',
			buttons: [{
				text: 'Ok',
				handler: () => {}
			}],
			backdropDismiss: false
		});
		await alert.present();
	}

	// Book Appointment Fail Status Alert
	async presentRegFailAlertAppointment(){
		const alert = await this.alertController.create({
			header: 'Book Appointment Status',
			subHeader: 'Failed',
			message: 'Something went Wrong. Please try again.',
			buttons: [{
				text: 'Ok',
				handler: () => {}
			}],
			backdropDismiss: false
		});
		await alert.present();
	}
}