import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortname'
})
export class SortnamePipe implements PipeTransform {
  // transform(value: any, ...args: any[]): any {
  transform(array: Array<any>, args: any): Array<any> {
    // return null;
    return array.sort((x: any, y: any) => {
      let a = x.args.toLowerCase();
      let b = y.args.toLowerCase();
      
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        // `enter code here`
        return 0;
      }
    });
    // return array;
  }

}
