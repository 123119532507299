import { Router } from '@angular/router';
// import { ApiService } from './api.service';
import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { mergeMap, map, finalize } from 'rxjs/operators';
import { catchError, switchMap, take, tap, filter } from 'rxjs/operators';
// import { filter } from 'rxjs-compat/operator/filter';
import { Storage } from '@ionic/storage';
import { LoadingService } from './loading.service';

const ACCESS_TOKEN = 'access_token';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private storage: Storage, 
    private alertCtrl: AlertController, 
    //private authenticationService: ApiService,
    // private apiService: ApiService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private router: Router){}

      /** Handle Interceptor errors - later update event error */
      handleError(error: HttpErrorResponse){
        
        console.log('error occured: ', error.error.error);
        console.log('error message: ', error.error.message);
        console.log('error message: ', error.error.status);

          if (error.status === 401) {
            console.log(error.error.error);
              if(error.error.error === 'invalid_token'){
                console.log('Do Refresh Token here', error.error.error);
                
                // this.authService
                // this.authService.getAccessTokenUsingRefreshToken();
                //Refresh Token

                // Logout for now till refresh token is implemented
                // this.router.navigate(['login']);
                // this.authService.logout();


                //https://medium.com/@monkov/angular-using-httpinterceptor-for-token-refreshing-3f04ea2ccb81
                // let params = {
                //     token: token,
                //     refreshToken: localStorange("refreshToken");
                //   };
                //   return this.http.post('localhost:8080/auth/refresh', params).flatMap(
                //     (data: any) => {
                //       //If reload successful update tokens
                //       if (data.status == 200) {
                //         //Update tokens
                //         localStorange.setItem("api-token", data.result.token);
                //         localStorange.setItem("refreshToken", data.result.refreshToken);
                //         //Clone our fieled request ant try to resend it
                //         req = req.clone({
                //           setHeaders: {
                //             'api-token': data.result.token
                //           }
                //         });
                //         return next.handle(req).catch(err => {
                //           //Catch another error
                //         });
                //       }else {
                //         //Logout from account
                //       }
                //     }
                //   );


                // this.handle401Error(req, next)
                    /** */
                    // this.authService.getAccessTokenUsingRefreshToken();
                    
                    // .pipe(
                    //   switchMap((token: any) => {
                    //     this.isRefreshing = false;
                    //     console.log(token);
                    //     console.log('executed3');
                    //     this.refreshTokenSubject.next(token.access_token);
                    //     return next.handle(this.addToken(request, token.access_token));
                    //   }));
                    /** */


              }else{
                console.log('Invalid Login, Do logout', error.error.error);
              }
            //this.authService.setLoggedUser(null);
          }
        return throwError(error);
      }

      /** Handle Login Errors */
      handleLoginError(error: HttpErrorResponse){
        console.log('error occured!' + JSON.stringify(error));
          if (error.status === 401) {
            console.log("Error Status: " + JSON.stringify(error.error.error));
            let message = error.error.error;
            // this.getAccessTokenUsingRefreshToken();
            // this.apiService.presentAlertApiHandler("LOGIN STATUS", "An Error Occured" + message, 'Try Again', '/login')
            
            // this.authService.authenticationState.next(false);
            // this.authService.logout();
          }else  if (error.status === 0) {
            console.log("Error Status is 0: " + JSON.stringify(error));
          }
        return throwError(error);
      }

      private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
          console.log('executed2');
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);
    
          return from(this.authService.getAccessTokenUsingRefreshToken())
          .pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;
              console.log(token);
              console.log('executed3');
              this.refreshTokenSubject.next(token.access_token);
              return next.handle(this.addToken(request, token.access_token));
            }));
    
        } else {
          return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(access_token => {
              return next.handle(this.addToken(request, access_token));
            }));
        }
      }
  
      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
      {  
  
        const apiUrl = "api/"; //Use this to search for api url's
        const loginUrl = "oauth/token";
        const regUrl = "Register"; //Use this to search for registration URL
        const providerRegUrl = "api/User/provider/Register";  //Use this to search for Provider registration
        //If Url is for login
        // if(req.url.search(loginUrl) >= 0){
          if (req.url.includes(loginUrl)){
            const headers = new HttpHeaders({
              Authorization: `Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0`,
              'Content-Type': `application/json`,
              Accept: `application/json, text/plain, */*`
            });
  
            const clone = req.clone({
              headers: headers
            });
            return next.handle(clone)
            .pipe(
              //tap(n => console.log(n)),
              //catchError(this.handleLoginError),
              //finalize(() => this.loadingService.loadingOff())
  
            );
          
          }else if(req.url.endsWith(providerRegUrl) || req.url.includes('blogPost/add') || req.url.includes('Prescription/add') || req.url.includes('clinic/add')){ 
            // else if(req.url.search(regUrl) >= 0){
              //console.log('Headers already supplied');
              let promise = this.storage.get(ACCESS_TOKEN);
  
              return from(promise)
              .pipe(
                  map(n => n),
                  tap(token => {console.log(token)}),
                  mergeMap(token => {
                    const clonedReq = this.addTokenWithoutHeader(req, token);
                    return next.handle(clonedReq)
                    .pipe(//This pipe is for handling errors
                      //tap(n => console.log(n)),
                      catchError(this.handleError)
                    )})
                )
              }else if(req.url.endsWith("UploadProfilePicture") || req.url.endsWith("UploadDocument") || req.url.endsWith("Profile")){ 
                // else if(req.url.search(regUrl) >= 0){
                  //console.log('Headers already supplied');
                  let promise = this.storage.get(ACCESS_TOKEN);
      
                  return from(promise)
                  .pipe(
                      map(n => n),
                      //tap(token => {console.log(token)}),
                      mergeMap(token => {
                        const clonedReq = this.addTokenWithoutHeader(req, token);
                        return next.handle(clonedReq)
                        .pipe(//This pipe is for handling errors
                          //tap(n => console.log(n)),
                          catchError(this.handleError)
                        )})
                    )
                  }else if(req.url.includes("DownloadDocument")){ 
                    // console.log("Headers: DownloadDocument");
                    // const headers = new HttpHeaders({
                    //   // 'Content-Type': 'application/octet-stream',
                    //   // 'Accept': 'application/octet-stream'  
                    //   // Accept: `application/json, text/plain, */*`
                    // });
          
                    // const clone = req.clone({
                    //   headers: headers
                    // });
                    // else if(req.url.search(regUrl) >= 0){
                      //console.log('Headers already supplied');
                      let promise = this.storage.get('ACCESS_TOKEN');
          
                      return from(promise)
                      .pipe(
                          map(n => n),
                          //tap(token => {console.log(token)}),
                          mergeMap(token => {
                            const clonedReq = this.addUploadTokenWithoutHeader(req, token);
                            return next.handle(clonedReq)
                            .pipe(//This pipe is for handling errors
                              //tap(n => console.log(n)),
                              catchError(this.handleError)
                            )})
                        )
                      }else if (req.url.includes("excludeheaders")){
            const headers = new HttpHeaders({
              // Authorization: `Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0`,
              // 'Content-Type': `application/json`,
              // Accept: `application/json, text/plain, */*`
            });
  
            const clone = req.clone({
              headers: headers
            });
            return next.handle(clone)
            .pipe(
              //tap(n => console.log(n)),
              catchError(this.handleError)
            );
          
          }
          // else if(req.url.search(regUrl) >= 0){
          //   this.storage.clear();
          // }
          else if(req.url.search(loginUrl) === -1){
            // console.log("Does not include loginUrl");
          let promise = this.storage.get(ACCESS_TOKEN);
  
                return from(promise)
                .pipe(
                    map(n => n),
                    // tap(token => {console.log(token)}), //console.log token
                    mergeMap(token => {
                      const clonedReq = this.addToken(req, token);
                      return next.handle(clonedReq)
                      .pipe(//This pipe is for handling errors
                        //tap(n => console.log(n)),
                        catchError(this.handleError)
                      )})
                  )
                }
  
            }
  
        // Adds the token to your headers if it exists
        private addToken(request: HttpRequest<any>, token: any) {
          if (token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
              setHeaders: {
                Accept: `application/json`,
                // 'Content-Type': `application/json`,
                Authorization: `Bearer ${token}`
              }
            });
            return clone;
          }
  
          return request;
        }
  
  
        // Adds the token to your headers if it exists
        private addTokenWithoutHeader(request: HttpRequest<any>, token: any) {
          if (token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
              setHeaders: {
                Accept: `application/json`,
                //'Content-Type': `application/json`,
                Authorization: `Bearer ${token}`
              }
            });
            return clone;
          }
  
          return request;
        }


        // Adds the token to your headers if it exists - this is for downloading images
       private addUploadTokenWithoutHeader(request: HttpRequest<any>, token: any) {
        if (token) {
          let clone: HttpRequest<any>;
          clone = request.clone({
            setHeaders: {
              // Accept: `application/json`,
              Authorization: `Bearer ${token}`
              //'Content-Type': `application/json`,
            }
          });
          return clone;
        }

        return request;
      }




      // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      //   if (!this.isRefreshing) {
      //     this.isRefreshing = true;
      //     this.refreshTokenSubject.next(null);
    
      //     return this.authService.getAccessTokenUsingRefreshToken().pipe(
      //       switchMap((token: any) => {
      //         this.isRefreshing = false;
      //         console.log(token);
      //         console.log('executed');
      //         this.refreshTokenSubject.next(token.access_token);
      //         return next.handle(this.addToken(request, token.access_token));
      //       }));
    
      //   } else {
      //     return this.refreshTokenSubject.pipe(
      //       filter(token => token != null),
      //       take(1),
      //       switchMap(access_token => {
      //         return next.handle(this.addToken(request, access_token));
      //       }));
      //   }
      // }


     


  
  
  }

  
  
  
    //     let isRefreshing = false;
    // let refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
    //  {
    //   let promise = this.storage.get('ACCESS_TOKEN');
  
    //   return from(promise).pipe(mergeMap(token => {
    //     const clonedReq = this.addToken(req, token);
    //     return next.handle(clonedReq).pipe(catchError(error => {
    //       if (error instanceof HttpErrorResponse && error.status === 401) {
    //         // console.log('executed');
    //         console.log(req);
    //         return this.handle401Error(req, next);
    //       } else {
    //         return throwError(error.message);
    //       }
    //     })
    //     );
    //   }
    //   ));
    // }
  
  
  
    //let currentUser : any = JSON.parse(this.storage.get(ACCESS_TOKEN));
          // if (currentUser){
          //     token = currentUser.access_token;
          // }else{token = ""}**
  
          // console.log("Token dobavljen sa localStorage.getItem:   ", token);
  
  
        // if (req.url.endsWith('/oauth/token')) 
        // {
        //   return next.handle(req);
        // }
      //   const ree = "/api";
      //   if (req.url.search(re) === -1 ) {
      //     req = req.clone({
      //       setHeaders: {
      //         Authorization: `Bearer da916e95-92c5-4b38-96ec-bb4ab5a45335`
      //       }
      //     });
      //   }
      //   //return next.handle(req);
      //   else{
  
      //   const headers = new HttpHeaders({
      //     //'Authorization': 'Bearer da916e95-92c5-4b38-96ec-bb4ab5a45335'
      //     Authorization: `Bearer da916e95-92c5-4b38-96ec-bb4ab5a45335`
      //   });
  
      //   const clone = req.clone({
      //     headers: headers
      //   });
      //   return next.handle(clone)
      //   .pipe(
      //     catchError(this.handleError)
      //   );
      // }
  
      // const apiUrl = "api/";
      // const loginUrl = "oauth/token";
      // if (req.url.search(loginUrl) === -1 ) {
      //   const headers = new HttpHeaders({
      //     //'Authorization': 'Bearer da916e95-92c5-4b38-96ec-bb4ab5a45335'
      //     Authorization: `Bearer ${token}`,
      //     Accept: `application/json, text/plain, */*`
      //   });
  
      //   const clone = req.clone({
      //     headers: headers
      //   });                                                  
      //   return next.handle(clone)
      //   .pipe(
      //     tap(n => console.log(n)),
      //     catchError(this.handleError)
      //   );
      // }else if(req.url.search(loginUrl) >= 0){
      //   const headers = new HttpHeaders({
      //     Authorization: `Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0`,
      //     'Content-Type': `application/json`,
      //     Accept: `application/json, text/plain, */*`
      //   });
  
      //   const clone = req.clone({
      //     headers: headers
      //   });
      //   return next.handle(clone)
      //   .pipe(
      //     tap(n => console.log(n)),
      //     catchError(this.handleError)
      //   );
      // //   options = {
      // //     headers: {
      // //         'Content-Type': 'application/json',
      // //         'Authorization': 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
      // //         'Accept': 'application/json, text/plain, */*'
      // //     }
      // // };
      // }
      
  // }
  // export class InterceptorService implements HttpInterceptor{
    // private isRefreshing = false;
    // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
    // constructor(private storage: Storage, private alertCtrl: AlertController, private authenticationService: ApiService) { }
    // intercept(req: HttpRequest<any>, next: HttpHandler):
    //   Observable<HttpEvent<any>> {
    //   let promise = this.storage.get('ACCESS_TOKEN');
  
    //   return from(promise).pipe(mergeMap(token => {
    //     const clonedReq = this.addToken(req, token);
    //     return next.handle(clonedReq).pipe(catchError(error => {
    //       if (error instanceof HttpErrorResponse && error.status === 401) {
    //         // console.log('executed');
    //         console.log(req);
    //         return this.handle401Error(req, next);
    //       } else {
    //         return throwError(error.message);
    //       }
    //     })
    //     );
    //   }
    //   ));
    // }